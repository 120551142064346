import axios from "axios"

function prices(url){
    var resp;
    resp = axios.get(url+"/api/v1/Price/GetPrices?symbols=EUR/USD,BTC/USD,ETH/USD,XAU/USD,XAG/USD,USD/CAD,EUR/GBP,GBP/USD,USD/JPY,LTC/USD,XRP/USD")
    .then((response) => response.data)
    .catch(err => console.log(err));

    return resp;
}
export default prices