import './App.css';
import Router from './router/router'

function App() {
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
