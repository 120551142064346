import { useEffect } from "react";
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { setForm } from "../../store/form";
import { setLanguage } from "../../store/language";
import { useTranslation } from 'react-i18next';

function Header(props) {
  var location = 'https://sca.' + (window.location.hostname.replace('www.', '')).replace('#', '');
  var wt = 'https://wt.' + (window.location.hostname.replace('www.', '')).replace('#', '');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('RUKAFXlanguage') === null) {
      localStorage.setItem('RUKAFXlanguage', "en");
      props.setLanguage("en")
      i18n.changeLanguage("en")
    }
    else {
      props.setLanguage(localStorage.getItem('RUKAFXlanguage'))
      i18n.changeLanguage(localStorage.getItem('RUKAFXlanguage'))
    }
  }, [])

  function changeLanguages(lang) {
    props.setLanguage(lang);
    localStorage.setItem('RUKAFXlanguage', lang);
    i18n.changeLanguage(lang)
    return null
  }

  return (
    <div style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
      <header id="header">
        <div className="container">
          <div className="d-flex-float-center">
            <h1 id="logo"><a href="/" title="RUKAFX">RUKAFX</a></h1>
            <div className="right-pane">
              <div className="hidden-xs">
                <a className="btn btn-outline-white border-0 btn-sm mr-4" href="https://api.whatsapp.com/send?phone=447465833191" target="_blank">
                  <img src="assets/img/whatsapp-white.svg" style={{ width: "15px", marginRight: "5px", marginTop: "-3px" }} />WhatsApp
                </a>
              </div>
              <div className="languages hidden-xs">
                <button style={props.language === "en" ? { background: "#fff", color: "#000", textTransform: "uppercase" } : { textTransform: "uppercase" }} onClick={() => { changeLanguages("en") }} className="btn btn-outline-white border-0 btn-sm mr-3">EN</button>
              </div>
              <div className="languages hidden-xs">
                <button style={props.language === "tr" ? { background: "#fff", color: "#000", textTransform: "uppercase" } : { textTransform: "uppercase" }} onClick={() => { changeLanguages("tr") }} className="btn btn-outline-white border-0 btn-sm mr-3">TR</button>
              </div>
              <div className="languages hidden-xs">
                <button style={props.language === "fa" ? { background: "#fff", color: "#000", textTransform: "uppercase" } : { textTransform: "uppercase" }} onClick={() => { changeLanguages("fa") }} className="btn btn-outline-white border-0 btn-sm">FA</button>
              </div>
              <div className="webtrader-link">
                <a href={wt} className="btn btn-outline-white border-0 btn-sm mx-2 hidden-xs ">{t("header.webTrader")}</a>
              </div>
                <a href={location} className="btn btn-outline-white border-0 btn-sm mx-2 hidden-xs">{t("header.login")}</a>
              <button onClick={() => { props.setApp(true); props.setForm("gercek"); }} className="btn btn-outline-white btn-sm hidden-xs">{t("header.createAccount")}</button>
            </div>
            <div className="menu-pane order-first">
              <nav className="navbar">
                <div className="container">
                  <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbar">
                    <i className="ui-icon ui-icon-menu-toggler collapsed-visible" />
                    <i className="ui-icon ui-icon-close collapsed-hidden" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbar">
                    <div className="nav-container">
                      <div className="align-items-center row">
                        <div className="col-12 col-sm-7">
                          <ul className="navbar-nav">
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/forex">
                                {t("Forex")}
                              </a>
                            </li>
                            {/* <li className="nav-item">
                                  <a className="nav-link" href="/kripto-paralar">
                                    {t("Kripto Paralar")}
                                  </a>
                                </li> */}
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/spread-rates">
                                {t("Spread Oranları")}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/trading-platforms">
                                {t("İşlem Platformları")}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/about-us">
                                {t("Hakkımızda")}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/announcement">
                                {t("Duyurular")}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/promotions">
                                {t("Promosyonlar")}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" style={{fontSize: 25}} href="/contact">
                                {t("İletişim")}
                              </a>
                            </li>
                          </ul>
                          <div className="d-sm-none">
                            <hr className="border-primary" />
                            <ul className="navbar-nav">
                              <li className="nav-item">
                                <a href={wt} className="nav-link">{t("WebTrader")}</a>
                              </li>
                              <li className="nav-item">
                                <a href={location} className="nav-link">{t("Giriş Yap")}</a>
                              </li>
                              <li className="nav-item">
                                <button style={{ background: "none", border: "none" }} onClick={() => { props.setApp(true) }} className="nav-link">{t("Hesap Oluştur")}</button>
                              </li>
                            </ul>
                          </div>
                          <div className="d-sm-none">
                            <hr className="border-primary" />
                            <button style={props.language === "en" ? { background: "#ddd", color: "#000", textTransform: "uppercase" } : { textTransform: "uppercase", color: "#000" }} onClick={() => { changeLanguages("en") }} className="btn btn-outline-white border-0 btn-sm mr-3">EN</button>

                            <button style={props.language === "tr" ? { background: "#ddd", color: "#000", textTransform: "uppercase" } : { textTransform: "uppercase", color: "#000" }} onClick={() => { changeLanguages("tr") }} className="btn btn-outline-white border-0 btn-sm mr-3">TR</button>

                            <button style={props.language === "fa" ? { background: "#ddd", color: "#000", textTransform: "uppercase" } : { textTransform: "uppercase", color: "#000" }} onClick={() => { changeLanguages("fa") }} className="btn btn-outline-white border-0 btn-sm">FA</button>
                          </div>
                        </div>
                        <ul className="navbar-nav">
                          <li className="nav-item">
                            <a className="nav-link ml-3" href="https://api.whatsapp.com/send?phone=447465833191" target="_blank">
                              <img src="assets/img/whatsapp-white.svg" style={{ width: "20px", marginRight: "5px", marginTop: "-3px" }} />WhatsApp
                            </a>
                          </li>
                        </ul>
                        <div className="col-12 col-sm-5">
                          <div className="social text-sm-right">
                            <a href="https://www.facebook.com/rukaforex" target="_blank" className="social-link"><i className="ui-icon ui-icon-social-fb" /> FACEBOOK</a>
                            <a href="https://twitter.com/Rukaforex" target="_blank" className="social-link"><i className="ui-icon ui-icon-social-tw" /> TWITTER</a>
                            <a href="https://www.instagram.com/rukaforex/" target="_blank" className="social-link"><i className="ui-icon ui-icon-social-ins" /> INSTAGRAM</a>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="text-sm text-secondary text-center mt-5 mt-sm-0">
                            {t("Copyright © 2021 RUKAFX. Tüm Hakları Saklıdır.")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    app: state.app.app,
    form: state.form.form,
    language: state.language.language,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
    setForm: (form) => dispatch(setForm(form)),
    setLanguage: (language) => dispatch(setLanguage(language)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);