import React, { useState, useEffect } from "react"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import CreateAccountHomepage from "../../components/CreateAccount/CreateAccountHomepage"
import InformationDirectoryModel from "../../model/InformationDirectoryModel"
import WhyRukaFX from "../../model/WhyRukaFX"
import LiveTitle from "../../model/LiveTitle"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { setForm } from "../../store/form";
import InfoPopup from "../../components/InfoPopup/InfoPopup"
import Marquee from "react-fast-marquee";
import Prices from "../../helpers/prices";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

function Homepage(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState([]);
  const [marqueeTop, setMarqueeTop] = useState({ marginTop: "-62px" });
  const { t } = useTranslation();

  const webtrader5 = 'https://webtrader.' + window.location.hostname.replace('www.', '') + "/5"

  useEffect(() => {

    Prices(process.env.REACT_APP_NAME).then((response) => {
      setPrice(response)
      console.log(response)
    })

    setInterval(() => {
      Prices(process.env.REACT_APP_NAME).then((response) => {
        setPrice(response)
      })
    }, 5000)

    $(window).on("load scroll resize", function () {
      const marquee = document.querySelector(".onStickyMarquee");
      if (window.scrollY > 785) {
        marquee.classList.add("marqueeFixed");
        setMarqueeTop({ marginTop: "0px", zIndex: 2 })
      } else {
        marquee.classList.remove("marqueeFixed");
        setMarqueeTop({ marginTop: "-62px", zIndex: 2 })
      }
    });
  }, [])

  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="carousel" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className data-slick data-slick-type="main">
            <div className="content" >
              <div className="img">
                <img src="temp/slider-main.jpg" alt="" className="img-fluid" />
              </div>
              <div className="content-text">
                <div className="container">
                  <h3 className="page-title text-white"></h3>
                  <div className="row">
                    <div className="col-12 col-md-10 offset-md-1">
                      <div className="markets">
                        <div className="carousel slider-markets">
                          <div className data-slick data-slick-type="markets" style={{ textAlign: "center" }}>
                            {
                              LiveTitle.map((item, index) => {
                                return (
                                  <div key={index + "-slider-" + item.title} className="market">
                                    <h3 className="page-title text-white">{t(item.title)}</h3>

                                  </div>
                                );
                              })
                            }
                          </div>
                          <div className="materails-container">
                            <div className="slick-arrows">
                              <button className="slick-arrow slick-prev" aria-label="Prev Slide">
                                <span className="ui-icon ui-icon-arrow-long-left" />
                              </button>
                              <button className="slick-arrow slick-next" aria-label="Next Slide">
                                <span className="ui-icon ui-icon-arrow-long-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CreateAccountHomepage />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="materails-container">
            <div className="container">
              <div className="dots" />
            </div>
          </div>
        </div>
        <div style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="onStickyMarquee" style={marqueeTop}>
            <div style={{ borderTop: "1px solid #F27031" }}></div>
            {/*
            <div className="marquee">
              {price ? <Marquee pauseOnHover speed={70} gradientWidth={0}>
                {price.map((item) => {
                  return (
                    <div className="prices">
                      <div className="row">
                        <div className="col-3">
                          {item.direction === -1 ? <img className="mt-2" src="assets/img/arrow-down-circle-fill.svg" alt="arrow-down-circle-fill" /> : <img className="mt-2" src="assets/img/arrow-up-circle-fill.svg" />}
                        </div>
                        <div className="col-9">
                          <p>{item.s} </p>
                          <p><b style={{ fontSize: "17px" }}>{item.a}</b> <span style={item.direction === -1 ? { color: "#FF0000" } : { color: "#00FF00" }}>{item.direction === -1 ? "-" : "+"}{item.changePercent}%</span></p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Marquee> : null}
            </div>
              */}
            <div style={{ borderTop: "1px solid #F27031" }}></div>
          </div>
        </div>
        <section className="section-main bg-white adim-3" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container">
            <div className="lp-xs-b3 lp-xs-t9">{t("")}
              <h3 className="page-title">{t("Sadece 3 Adımda Yatırım Yapmaya Başlayın!")}</h3>
              <p className="sub-title">{t("Evrak gerekmeden; hızlı , kolay ve güvenli bir şekilde işlem yap!")}</p>
              <div className="lm-xs-b6" />
              <div className="row align-items-center">
                <div className="col-12 col-md">
                  <div className="card">
                    <div className="card-image">
                      <i className="ui-icon ui-icon-kayit-form" />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title text-secondary text-normal mb-0">{t("Kayıt Formunu Doldurun")}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <div className="circle-arrow" />
                </div>
                <div className="col-12 col-md">
                  <div className="card">
                    <div className="card-image">
                      <i className="ui-icon ui-icon-onay-islemi" />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title text-secondary text-normal mb-0">{t("Onay İşlemini Tamamlayın")}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <div className="circle-arrow" />
                </div>
                <div className="col-12 col-md">
                  <div className="card">
                    <div className="card-image">
                      <i className="ui-icon ui-icon-satis-yapin" />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title text-secondary text-normal mb-0">{t("Alış/Satış Yapmaya Başlayın")}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lm-xs-b5" />
              <div className="row justify-content-center">
                <div className="col-12 col-md-3 text-center">
                  <a onClick={() => { props.setApp(true); props.setForm("demo") }} className="btn btn-primary btn-lg d-md-block" style={{ color: "#fff" }}>{t("HAZIRSAN BAŞLAYALIM!")}</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-main bg-white why-btc" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container">
            <div className="lp-xs-b6 lp-xs-t6">
              <h3 className="page-title">{t("Neden RUKAFX")}</h3>
              <p className="sub-title">{t("Yatırım dünyasında sınırlara takılmadan işlem yapabilme özğürlüğü RukaFX’te!")}</p>
              <div className="lm-xs-b6" />
              <div className="row">
                {
                  WhyRukaFX.map((item, index) => {
                    return (
                      <div key={"why-RUKAFX-" + index} className="col-12 col-md-4">
                        <div className="card no-bg" style={{ minHeight: "415px" }}>
                          <div className="card-image">
                            <i className={"ui-icon " + item.icon} />
                          </div>
                          <div className="card-body">
                            <h3 className="card-title">{t(item.title)}</h3>
                            <p>{t(item.description)}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </section>
        <section className="section-main data-bank" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container">
            <div className="lp-xs-t4 lp-sm-t7" />
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h2 className="page-title text-white">{t("RUKAFX Bilgi Rehberi")}</h2>
              </div>
            </div>
            <div className="lm-xs-b3" />
            <div className="carousel slider-trade">
              <div className data-slick data-slick-type="trade">
                {
                  InformationDirectoryModel.map((item, index) => {
                    return (
                      <div info-directory key={"info-" + index} className="content" >
                        <div className="d-none">
                          <InfoPopup title={title} description={description} />
                        </div>
                        <a href="#info-directory" onClick={() => { setTitle(t(item.title)); setDescription(t(item.description)) }} className="fancy-box">
                          <div className="contain">
                            <div className="content-text ">
                              <h3 className="title">{t(item.title)}</h3>
                              <div style={{ maxHeight: "190px", overflow: "hidden" }}>
                                <p style={{ color: "#9CA9B7" }}>{t(item.description)}</p>
                              </div>
                              <p style={{ color: "#9CA9B7" }}>...</p>
                              <i className="ui-icon ui-icon-arrow-long-right mt-5" />
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })
                }
              </div>
              <div className="materails-container">
                <div className="slick-arrows">
                  <button className="slick-arrow slick-prev" aria-label="Prev Slide">
                    <span className="ui-icon ui-icon-arrow-long-left" />
                  </button>
                  <button className="slick-arrow slick-next" aria-label="Next Slide">
                    <span className="ui-icon ui-icon-arrow-long-right" />
                  </button>
                </div>
              </div>
            </div>
            <div className="lp-xs-b6" />
          </div>
        </section>
        <section className="section-main bg-white isik-hiziyla" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-xs-12 offset-1">
                <div className="text-container pr-5">
                  <h3 className="title">{t("RUKAFX ile Işık Hızında Forex Ve Kripto Para İşlemleri")}</h3>
                  <p className="mt-5">{t("İnternetten yatırım teknolojisinin nimetlerinden faydalanmak için en hızlı teknolojiyi tercih edin. En son teknoloji Ruka Yatırım Platformunu indirip yatırım işlemlerine başlayın!")}</p>
                  {/* <div className="row">
                    <div className="col-xl-6 col-md-6 col-xs-12">
                      <a href="https://download.mql5.com/cdn/mobile/mt5/android?server=BTCrukaInvestment-Live" className="btn btn-primary btn-lg d-md-block mb-2" style={{ color: "#fff", backgroundColor: "#000", border: "none", textAlign: "left", minWidth: "220px" }}>
                        <div className="row">
                          <div className="col-3">
                            <img src="assets/img/gplay-logo.png" style={{ width: "30px", paddingTop: "9px" }} />
                          </div>
                          <div className="col-9">
                            <small style={{ fontSize: "10px" }}>GET IT ON</small><br />Google Play
                          </div>
                        </div>
                      </a>

                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-12">
                      <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=BTCrukaInvestment-Live" className="btn btn-primary btn-lg d-md-block mb-2" style={{ color: "#fff", backgroundColor: "#000", border: "none", textAlign: "left", minWidth: "220px" }}>
                        <div className="row">
                          <div className="col-3">
                            <img src="assets/img/apple-logo.png" style={{ width: "30px", paddingTop: "6px" }} />
                          </div>
                          <div className="col-9">
                            <small style={{ fontSize: "10px" }}>Available on the iPhone</small><br />App Store
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-xl-6 col-md-6 col-xs-12">
                      <a href={webtrader5} className="btn btn-primary btn-lg d-md-block mb-2" style={{ color: "#fff", minWidth: "220px" }}><small style={{ fontSize: "10px" }}>{t("Hemen Deneyimle")}</small><br />WebTrader 5</a>
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-12">
                      <a href="https://download.mql5.com/cdn/web/19007/mt5/btcrukainvestment5setup.exe" className="btn btn-primary btn-lg d-md-block" style={{ color: "#fff", minWidth: "220px" }}><small style={{ fontSize: "10px" }}>{t("Şimdi İndirin")}</small><br />MetaTrader 5</a>
                    </div>
                  </div> */}
                </div>
              </div>


              <div className="col-6">
                <div className="img" >
                  <img src="./assets/img/proccessings.png" alt="RUKAFX ile Işık Hızında Kripto Para İşlemleri" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <StartToday />
        <Footer />

        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
    form: state.form.form,
    language: state.language.language,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
    setForm: (form) => dispatch(setForm(form)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

