import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from '../pages/Homepage';
import Announcement from '../pages/Announcement';
import Spreads from '../pages/Spreads';
import AboutUs from '../pages/AboutUs';
import Forex from '../pages/Forex';
import Contact from '../pages/Contact';
// import CryptoCoins from '../pages/CryptoCoins';
import Cfd from '../pages/CDF'
import Emtia from '../pages/Emtia'
import CurrencyPairs from '../pages/CurrencyPairs'
import Promotions from '../pages/Promotions'
import Promotion from '../pages/Promotion'
import NotFound from '../pages/NotFound';
import PrivacyAndSecurity from '../pages/PrivacyAndSecurity';
import TradingPlatforms from '../pages/TradingPlatforms/TradingPlatforms';

const Router = (props) => {
  return (
    <div>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/announcement" component={Announcement} />
                <Route exact path="/spread-rates" component={Spreads} />
                <Route exact path="/forex" component={Forex} />
                <Route exact path="/contact" component={Contact} />
                {/* <Route exact path="/kripto-paralar" component={CryptoCoins} /> */}
                <Route exact path="/cfd" component={Cfd} />
                <Route exact path="/commodities" component={Emtia} />
                <Route exact path="/currency-pairs" component={CurrencyPairs} />
                <Route exact path="/promotions" component={Promotions} />
                <Route exact path="/promotion" component={Promotion} />
                <Route exact path="/trading-platforms" component={TradingPlatforms} />
                <Route exact path="/privacy-and-security-policy" component={PrivacyAndSecurity} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    </div>
  );
};
export default Router;