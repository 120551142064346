import React, { useState, useEffect } from "react"
import connector from "../../helpers/connector"
import InputMask from 'react-input-mask';
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { useTranslation } from 'react-i18next';

function Contact(props) {

  const [fullnameContact, setFullNameContact] = useState("");
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("");
  const [validator, setValidator] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [kvkk, setKvkk] = useState(false);
  const [form, setForm] = useState("");
  const [status, setStatus] = useState("form");
  const { t } = useTranslation();
  const [time, setTime] = useState(0)

  let brandType = 0;
  var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
  // let formUrl = `https://ruka.testanka.com/api/v1/Submit?brand=${brandType}`;

  let timeInterval;

  var data = {
    "firstName": fullnameContact,
    "lastName": surname,
    "email": email,
    "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    "message": message
  }
  // var data = {
  //   domain: window.location.host.replace("iletisim", ""),
  //   formSystemName: "RealAccountForm",
  //   formValues: {
  //     "firstName": fullnameContact,
  //     "lastName": surname,
  //     "email": email,
  //     "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
  //     "country": "Turkey",
  //     "countryCode": "+90",
  //     "approvedConditions": kvkk,
  //     "message": message
  //   },
  //   languageIsoCode: 'tr',
  //   siteSystemName: 'RUKAFXWeb',

  // }
  function EmailChange(e) {
    const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let isOk = validEmail.test(String(e).toLowerCase())
    if (isOk) {
      setEmail(e);
      setValidator(true)
    }
    else {
      setEmail(e);
      setValidator(false)
    }
  }

  //timer settings start
  let timer = new Date()
  const handleTime = () => {
    let clickTime = timer.getTime()
    localStorage.setItem('clickTimeRukaContact', clickTime)
    setTime(600000)
    reduceTime()
  }

  const reduceTime = async () => {
    if (localStorage.getItem('clickTimeRukaContact')) {
      timeInterval = setInterval(() => {
        setTime((time) => time - 1000)
      }, 1000);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('clickTimeRukaContact')) {
      let newTime = timer.getTime()
      let oldTime = localStorage.getItem('clickTimeRukaContact')
      let timeDifference = newTime - oldTime
      if (timeDifference > 600000) {
        setTime(0)
      } else {
        setTime((600000 - timeDifference))
        reduceTime()
      }
    }
  }, [])

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    if (millis === 0 || millis < 0) {
      localStorage.removeItem('clickTimeRukaContact')
      setTime(0)
      clearInterval(timeInterval)
    }
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  async function Submit() {
    handleTime()

    connector(formUrl, data)
      .then(data => {
        if (data.success) {
          setStatus("ok")
          handleTime()
        }
        else {
          setStatus("error")
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            setStatus("error")
          }
          else {
            setStatus("ok")
            handleTime()

          }
        }
        else {
          setStatus("error")
        }
      })
  }
  useEffect(() => {

    var isNull = false
    if (fullnameContact === "" || surname === "" || email === "" || phone === "" || phone.includes("_") || message === "" || kvkk === false || validator === false) {
      isNull = false
    }
    else {
      isNull = true
    }
    if (status === "form") {
      setForm(
        <div action className="form">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <input type="text" name="fullnameContact" id="fullnameContact" className="form-control" placeholder={t("Adınız")} onChange={event => { setFullNameContact(event.target.value) }} />
                <label htmlFor="fullnameContact">{t("Adınız")}</label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <input type="text" name="surname" id="surname" className="form-control" placeholder={t("Soyadınız")} onChange={event => { setSurname(event.target.value) }} />
                <label htmlFor="fullnameContact">{t("Soyadınız")}</label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <input type="email" name="emailContact" id="emailContact" className="form-control" placeholder={t("E-Posta Adresiniz")} value={email} onChange={event => { EmailChange(event.target.value) }} />
                <label htmlFor="email">{t("E-Posta Adresiniz")}</label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <InputMask mask="(999) 999 99 99" value={phone} type="phone" name="phoneContact" id="phoneContact" className="form-control" placeholder={t("Telefon Numaranız")} onChange={event => { setPhone(event.target.value) }}></InputMask>
                <label htmlFor="phone">{t("Telefon Numaranız")}</label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <textarea name="messageContact" id="messageContact" rows={3} className="form-control" placeholder="Mesaj" defaultValue={""} onChange={event => { setMessage(event.target.value) }} />
            <label htmlFor="message">{t("Mesaj")}</label>
          </div>
          <div className="form-group lm-xs-t2 lm-xs-b4">
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="kvkkContact" onClick={() => { setKvkk(kvkk ? false : true) }} />
              <label className="custom-control-label" htmlFor="kvkkContact">
                <span style={{ color: "#F27031" }}>
                  {t("Kişisel Verilerin Korunması, İşlenmesine Dair Sözleşme ve Aydınlatma Metni’ni kabul ediyorum.")}
                </span>
              </label>
            </div>
          </div>
          <div className="text-center">
            {time > 0 ? <button type="submit" className="btn btn-primary btn-submit" disabled>{millisToMinutesAndSeconds(time)}</button> :
              isNull ?
                <button onClick={() => { Submit() }} type="submit" className="btn btn-primary btn-submit">{t("GÖNDER")}</button>
                :
                <button type="submit" className="btn btn-primary btn-submit" disabled>{t("GÖNDER")}</button>
            }


          </div>
        </div>
      )
    }
    else if (status === "error") {
      setForm(
        <div className="mt-5" style={{ textAlign: "center" }}>
          <div className="row">
            <div className="col-12">
              <img className="mb-3" src="assets/img/error.svg" alt="PORTFX" />
              <h4 className="mb-4 mt-2">
                {t("Bir Hata Oluştu!")}
              </h4>
              <p>{t("Lütfen sayfayı yenileyip tekrar deneyin.")}</p>
            </div>
          </div>
        </div>
      )
    }
    else if (status === "ok") {
      setForm(
        <div className="mt-5" style={{ textAlign: "center" }}>
          <div className="row">
            <div className="col-12">
              <img className="mb-3" src="assets/img/success.svg" alt="PORTFX" />
              <h4 className="mb-4 mt-2">
                {t("Mesajınız Alındı!")}
              </h4>
              <p>{t("Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.")}</p>
            </div>
          </div>
        </div>
      )
    }
  }, [status, fullnameContact, email, phone, message, kvkk, validator, t]);

  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="img">
            <img src="temp/placard-iletisim.jpg" alt="Hakkımızda" />
          </div>
          <div className="text-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <h2 className="page-title">{t("İletişim")}</h2>
                  <p style={{ color: "#fff" }}>{t("Aklınıza takılan her türlü soru, görüş ve önerileriniz için bizimle iletişime geçebilirsiniz.")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-main" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container">
            <div className="page-content">
              <div className="floating-form-container">
                <div className="floating-form contact">
                  {form}
                </div>
              </div>
              <div className="row" style={{ marginTop: "100px" }}>
                <div className="col-xs-12 col-md-1 col-lg-1 mt-2 contact-image">
                  <img src="assets/img/phone.svg" style={{ width: "27px" }} />
                </div>
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <small style={{ color: "#F27031" }}>{t("Telefon Numarası")}</small>
                  <h5><a style={{ color: "#9CA9B7" }} href="+447355414813">+44 7355 414 813</a></h5>
                </div>
                <div className="col-xs-12 col-md-1 col-lg-1 mt-2 contact-image">
                  <img src="assets/img/whatsapp.svg" style={{ width: "27px" }} />
                </div>
                <div className="col-xs-12 col-md-3 col-lg-">
                  <small style={{ color: "#F27031" }}>Whatsapp</small>
                  <h5><a style={{ color: "#9CA9B7" }} href="https://api.whatsapp.com/send?phone=447465833191">+44 7355 414 813</a></h5>
                </div>
                <div className="col-xs-12 col-md-1 col-lg-1 mt-2 contact-image">
                  <img src="assets/img/mail.svg" style={{ width: "35px" }} />
                </div>
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <small style={{ color: "#F27031" }}>{t("E-Posta Adresi")}</small>
                  <h5>
                    <a style={{ color: "#9CA9B7" }} href="mailto:support@rukafx.com">support@rukafx.com</a>
                  </h5>
                </div>
                <div className="col-xs-12 col-md-1 col-lg-1 mt-4 contact-image">
                  <img src="assets/img/home.svg" style={{ width: "27px" }} />
                </div>
                <div className="col-xs-12 col-md-3 col-lg-11 mt-3">
                  <small style={{ color: "#F27031" }}>{t("Adres")}</small>
                  <h5><a style={{ color: "#9CA9B7" }} href="https://www.google.com/maps/place/Spaces+-+London,+Spaces+Liverpool+Street+Station/@51.5169334,-0.0859393,17z/data=!3m1!4b1!4m5!3m4!1s0x487603529b7164a9:0x7f900cee9e23f037!8m2!3d51.5169334!4d-0.0837506" target="_blank">New Broad Street House 35 New Broad St, London EC2M 1NH, UK</a></h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <StartToday />
        <Footer />
        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
