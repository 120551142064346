import React, { useState } from "react"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { setForm } from "../../store/form";
import CryptoModel from "../../model/CryptoModel"
import InformationDirectoryModel from "../../model/InformationDirectoryModel"
import InfoPopup from "../../components/InfoPopup/InfoPopupFooter"
import { useTranslation } from 'react-i18next';

function Footer(props) {
  const [title, setTitleFooter] = useState("");
  const [description, setDescriptionFooter] = useState("");
  const { t } = useTranslation();

  var dt = new Date();

  return (
    <div style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
      <footer id="footer">
        <div className="container">
          <div className="top">
            <a href="/">
              <img src="assets/img/logo.png" className="logo" style={{ height: "70px", width: "300px" }} alt="RUKAFX" />
            </a>
          </div>
          <ul className="list-unstyled list">
            <li>
              <span className="foot-title">{t("Forex")}</span>
              <ul className="list-unstyled">
                {/* <li><a href={"/forex"}>Forex Nedir?</a></li> */}
                <li><button style={{ background: "none", color: "#BBCCDE", border: "none" }} onClick={() => { props.setForm("demo"); props.setApp(true) }}>{t("Demo Hesap Aç")}</button></li>
                <li><button style={{ background: "none", color: "#BBCCDE", border: "none" }} onClick={() => { props.setForm("gercek"); props.setApp(true) }}>{t("Gerçek Hesap Aç")}</button></li>

                <li><a href={"/currency-pairs"}>{t("Döviz Çiftleri")}</a></li>
                <li><a href={"/cfd"}>{t("CFD İşlemleri")}</a></li>
                <li><a href={"/commodities"}>{t("Emtialar")}</a></li>
                <li><a href={"/promotions"}>{t("Promosyonlar")}</a></li>
              </ul>
            </li>
            {/* <li>
              <span className="foot-title">{t("Kripto Paralar")}</span>
              <ul className="list-unstyled">
                {
                  CryptoModel.map((item, index) => {
                    if (index <= 3) {
                      return (
                        <li key={"CryptoFooter-" + item.coin}><a href={"/kripto-paralar#" + item.coin}>{item.coin}</a></li>
                      );
                    }
                    else {
                      return null
                    }
                  })
                }
                <li><a href="/kripto-paralar">{t("Daha Fazla...")}</a></li>
              </ul>
            </li> */}
            <li>
              <span className="foot-title">{t("Kurumsal")}</span>
              <ul className="list-unstyled">
                <li><a href="/about-us">{t("Hakkımızda")}</a></li>
                <li><a data-toggle="modal" data-target="#exampleModalFooter" className="certificate-button">{t("RukaFX Lisansını Görüntüle")}</a></li>
                <li><a href="/announcement">{t("Duyurular")}</a></li>
                <li><a href="/spread-rates">{t("Spread Oranları")}</a></li>
                <li><a href="/contact">{t("İletişim")}</a></li>
                <li><a href="/privacy-and-security-policy">{t("Gizlilik ve Güvenlik Politikası")}</a></li>
                {/* <li><button style={{ display: "none" }} data-toggle="modal" data-target="#announceFooter" className="certificate-button">RukaFX Lisansını Görüntüle</button></li> */}
              </ul>
            </li>
            {/* <li>
                  <span className="foot-title">Yasal</span>
                  <ul className="list-unstyled">
                    <li><a href="#">Kullanım Sözleşmesi</a></li>
                    <li><a href="#">Gizlilik Sözleşmesi</a></li>
                    <li><a href="#">Kulllanıcı Verilerini Koruma</a></li>
                    <li><a href="#">Risk Bildirisi</a></li>
                  </ul>
                </li> */}
            <li>
              <span className="foot-title">{t("Bilgi Rehberi")}</span>
              <ul className="list-unstyled">
                {
                  InformationDirectoryModel.map((item, index) => {
                    return (
                      <div key={"InformationFooter-" + item.title}>
                        <div className="d-none">
                          <InfoPopup title={t(title)} description={t(description)} />
                        </div>
                        <li>
                          <a href="#info-directory-footer" key={item.title} onClick={() => { setTitleFooter(t(item.title)); setDescriptionFooter(t(item.description)) }} className="fancy-box">
                            {t(item.title)}
                          </a>
                        </li>
                      </div>

                    );
                  })
                }
              </ul>
            </li>
            <li>
              <span className="foot-title"><a href="/contact">{t("İletişim")}</a></span>
              <ul className="list-unstyled">
                <li><img src="assets/img/home.svg" style={{ width: "15px", marginRight: "5px" }} /><a href="https://www.google.com/maps/place/Spaces+-+London,+Spaces+Liverpool+Street+Station/@51.5169334,-0.0859393,17z/data=!3m1!4b1!4m5!3m4!1s0x487603529b7164a9:0x7f900cee9e23f037!8m2!3d51.5169334!4d-0.0837506" target="_blank">New Broad Street House 35 New <br /> Broad St, London EC2M 1NH, UK</a></li>
                <li><img src="assets/img/mail.svg" style={{ width: "15px", marginRight: "5px" }} /><a href="mailto:support@rukafx.com">support@rukafx.com</a></li>
                <li><img src="assets/img/whatsapp.svg" style={{ width: "15px", marginRight: "5px" }} /><a href="https://api.whatsapp.com/send?phone=447355414813">+44 7355 414 813</a></li>

              </ul>
            </li>
          </ul>
          <div className="d-flex-float-center flex-column flex-sm-row foot">
            <p className="text-sm mb-sm-0">
              Copyright © {dt.getFullYear()} RUKAFX. {t("Tüm Hakları Saklıdır.")}
            </p>
            {/* <p className="text-sm mb-sm-0 text-center">
              <b>rukafx.com {t("BTCRuka Investment Ltd'ye aittir ve onun tarafından işletilmektedir.")}</b>
            </p> */}
            <div className="social">
              <a href="https://www.facebook.com/rukaforex" target="_blank" className="social-link"><i className="ui-icon ui-icon-social-fb" /></a>
              <a href="https://twitter.com/Rukaforex" target="_blank" className="social-link"><i className="ui-icon ui-icon-social-tw" /></a>
              <a href="https://www.instagram.com/rukaforex/" target="_blank" className="social-link"><i className="ui-icon ui-icon-social-ins" /></a>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModalFooter" tabIndex="-1" role="dialog" style={{ marginTop: "30px", zIndex: 9999999999999999999 }} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="certificate-popups">
              <div className="modal-content" >
                <div className="modal-header">
                  <h5 className="modal-title text-dark" id="exampleModalLabel" style={{ padding: 20 }}>{t("ASIC Sertifikası")}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginTop: "20px", marginRight: "30px", boxShadow: "none" }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <img className="certificate-image" src="assets/img/asic.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
    form: state.form.form,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
    setForm: (form) => dispatch(setForm(form)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);