import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { useTranslation } from 'react-i18next';

function NotFound(props) {
  const { t } = useTranslation();
  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="img">
            <img src="temp/placard-duyurular.jpg" alt="Duyurular" />
          </div>
          <div className="text-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <h1 style={{ fontSize: "80px" }} className="page-title"><b>404</b></h1>
                  <h2 className="page-title mb-5"> {t("Sayfa Bulunamadı !")}</h2>
                  <p>{t("Ulaşmaya çalıştığınız sayfa yayında değil veya link adresi değiştirilmiş olabilir. Anasayfaya dönmek için butona tıklayabilirsiniz.")}</p>
                </div>
              </div>
              <div className="row mt-5 justify-content-center">
                <a href="/" className="btn btn-primary btn-lg d-md-block" style={{ color: "#fff" }}>{t("ANASAYFAYA DÖN")}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <StartToday />
        </div>
        <Footer />
        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotFound);