import {connect} from "react-redux";

function InfoPopup(props){
    return(
        <div id="info-directory-footer" style={{width:"800px"}}>
        <div className="page-content mt-0" style={{background:"#555"}}>
            <div className="collapse coin-buy-panel show" style={ props.language === "fa" ? { direction : "rtl", textAlign: "right"} : {}}>
                <h2 className="page-title text-md mb-5" style={ props.language === "fa" ? { direction : "rtl", textAlign: "right", color: "#F27031"} : {color: "#F27031"}}>{props.title}</h2>
                <p style={{color: "#FFF"}}>{props.description}</p>
            </div>
        </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        language: state.language.language
    }
  }
  const mapDispatchToProps = dispatch => {
    return { }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(InfoPopup);
