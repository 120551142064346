import React, { useState, useEffect, useRef } from "react"
import connector from "../../helpers/connector"
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { setForm } from "../../store/form";
import { useTranslation } from 'react-i18next';

function CreateAccountPopup(props) {
  const { t } = useTranslation();
  const [fullnamePopup, setFullNamePopup] = useState("");
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("");
  const [validator, setValidator] = useState("");
  const [phone, setPhone] = useState("");
  const [kvkkPopup, setKvkkPopup] = useState(false);
  const [form, setForm] = useState("");
  const [status, setStatus] = useState("form");
  const [time, setTime] = useState(0)

  const click = useRef()

  let brandType = 0;
  var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
  // var formUrl = `https://ruka.testanka.com/api/v1/Submit?brand=${brandType}`;

  let timeInterval;

  var data = {
    "firstName": fullnamePopup,
    "lastName": surname,
    "email": email,
    "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", "")
  }
  // var data = {
  //   domain: window.location.href,
  //   formSystemName: props.form === "gercek" ? "RealAccountForm" : "DemoForm",
  //   formValues: {
  //     "firstName": fullnamePopup,
  //     "lastName": surname,
  //     "email": email,
  //     "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
  //     "country": "Turkey",
  //     "countryCode": "+90",
  //     "approvedConditions": kvkkPopup
  //   },
  //   languageIsoCode: 'tr',
  //   siteSystemName: 'RUKAFXWeb',

  // }
  function EmailChange(e) {
    const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let isOk = validEmail.test(String(e).toLowerCase())
    if (isOk) {
      setEmail(e);
      setValidator(true)
    }
    else {
      setEmail(e);
      setValidator(false)
    }
  }

  //timer settings start
  let timer = new Date()
  const handleTime = () => {
    let clickTime = timer.getTime()
    localStorage.setItem('clickTimeRuka', clickTime)
    setTime(600000)
    reduceTime()
  }

  const reduceTime = async () => {
    if (localStorage.getItem('clickTimeRuka')) {
      timeInterval = setInterval(() => {
        setTime((time) => time - 1000)
      }, 1000);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('clickTimeRuka')) {
      let newTime = timer.getTime()
      let oldTime = localStorage.getItem('clickTimeRuka')
      let timeDifference = newTime - oldTime
      if (timeDifference > 600000) {
        setTime(0)
      } else {
        setTime((600000 - timeDifference))
        reduceTime()
      }
    }
  }, [])

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    if (millis === 0 || millis < 0) {
      localStorage.removeItem('clickTimeRuka')
      clearInterval(timeInterval)
      setTime(0)
    }
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  //timer settings end

  async function Submit() {

    connector(formUrl, data)
      .then(data => {
        if (data.success) {
          setStatus("ok")
          handleTime()
        }
        else {
          setStatus("error")
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            setStatus("error")
          }
          else {
            setStatus("ok")
          }
        }
        else {
          setStatus("error")
        }
      })
  }
  const closePopup = (e) => {
    if (e.target.className.includes('close-modal')) {
      props.setApp(false)
    }
  }
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.setApp(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  useEffect(() => {
    var isNull = false
    if (fullnamePopup === "" || surname === "" || email === "" || phone === "" || phone.includes("_") || kvkkPopup === false || validator === false) {
      isNull = false
    }
    else {
      isNull = true
    }
    if (status === "form") {
      setForm(
        <div className="row" >
          <div className="col-12 col-md-3">
            <h4 className="page-title text-left mb-0">
              {props.form === "gercek" && t("Gerçek Hesap Oluşturun")}
              {props.form === "demo" && t("Demo Hesap Oluşturun")}
            </h4>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    type="text"
                    name="fullnamePopup"
                    id="fullnamePopup"
                    className="form-control"
                    placeholder="Adınız"
                    onChange={event => { setFullNamePopup(event.target.value) }}
                  />
                  <label htmlFor="fullnamePopup">{t("Adınız")}</label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    className="form-control"
                    placeholder="Soyadınız"
                    onChange={event => { setSurname(event.target.value) }}
                  />
                  <label htmlFor="fullnamePopup">{t("Soyadınız")}</label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    type="email"
                    name="emailPopup"
                    id="emailPopup"
                    className="form-control"
                    placeholder="E-Posta Adresiniz"
                    value={email}
                    onChange={event => { EmailChange(event.target.value) }}
                  />
                  <label htmlFor="email">{t("E-Posta Adresiniz")}</label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <InputMask mask="(999) 999 99 99" value={phone} type="phone" name="phonePopup" id="phonePopup" className="form-control" placeholder="Telefon Numaranız" onChange={event => { setPhone(event.target.value) }}>

                  </InputMask>
                  <label htmlFor="phone">{t("Telefon Numaranız")}</label>

                </div>
              </div>
              <div className="col-12">
                <div className="form-group lm-xs-t2">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="kvkkPopup"
                      onClick={() => { setKvkkPopup(kvkkPopup ? false : true); }}
                    />
                    <label className="custom-control-label" htmlFor="kvkkPopup">
                      <span style={{ color: "#F27031" }}>{t("Kişisel Verilerin Korunması, İşlenmesine Dair Sözleşme ve Aydınlatma Metni’ni kabul ediyorum.")}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right mb-5">
              {time > 0 ? <button type="submit" className="btn btn-primary btn-submit" disabled>{millisToMinutesAndSeconds(time)}</button> :
                isNull ?
                  <button onClick={() => { Submit() }} type="submit" className="btn btn-primary btn-submit">{t("HESAP OLUŞTUR")}</button>
                  :
                  <button type="submit" className="btn btn-primary btn-submit" disabled>{t("HESAP OLUŞTUR")}</button>
              }

            </div>
          </div>
        </div>
      )
    }
    else if (status === "error") {
      setForm(
        <div>
          <div className="row">
            <div className="col-3">
              <img className="mb-3" src="assets/img/error.svg" alt="PORTFX" />
            </div>
            <div className="col-9">
              <h4 className="mb-4 mt-2">
                {t("Bir Hata Oluştu!")}
              </h4>
              <p>{t("Lütfen sayfayı yenileyip tekrar deneyin.")}</p>
            </div>
          </div>
        </div>
      )
    }
    else if (status === "ok") {
      setForm(
        <div>
          <div className="row">
            <div className="col-3">
              <img className="mb-3" src="assets/img/success.svg" alt="PORTFX" />
            </div>
            <div className="col-9">
              <h4 className="mb-4 mt-2">
                {t("Üyelik İsteğiniz Alındı!")}
              </h4>
              <p>{t("Üyeliğinizi Aktif Etmek İçin Lütfen E-mail Kutunuzu Kontrol Ediniz.")}</p>
            </div>
          </div>


        </div>
      )
    }
  }, [status, fullnamePopup, email, phone, kvkkPopup, validator, props.form, t]);

  return (
    <div className="full-modal close-modal" onClick={(e) => closePopup(e)} >
      <div className="container close-modal">
        <div className="close-modal row justify-content-center align-items-center min-vh-100">
          <div className="close-modal col-12 col-sm-8">
            <div className="floating-form" style={{ boxShadow: "0px 30px 20px rgba(30,37,42,0.4)" }}>
              <div action className="form" >
                {props.app ? <button onClick={() => { props.setApp(false) }} className="close-popup-button">KAPAT</button> : ""}
                {form}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => {
  return {
    app: state.app.app,
    form: state.form.form,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
    setForm: (form) => dispatch(setForm(form)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPopup);