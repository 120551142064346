const AnnouncementModel = [
    {
        title:"RukaFX Türkçe Dil Desteği Vermeye Başladı!",
        date:"20.08.2021",
        description:"5 ayrı ülkede yatırımcılara hizmet veren RukaFX; Türkçe dil desteği hizmetine başladı! Türk yatırımcılara özel olarak hazırlanan RukaFX Türkçe dil destekli web sitesi; uzman danışmanlar ve son teknoloji alt yapısı ile ayrıcalıklı yatırım yapmak isteyenleri bekliyor."
    },
    {
        title:"RukaFX Farsça Dil Desteği Vermeye Başladı!",
        date:"30.03.2022",
        description:"5 ayrı ülkede yatırımcılara hizmet veren RukaFX; Farsça dil desteği hizmetine başladı! Türk yatırımcılara özel olarak hazırlanan RukaFX Farsça dil destekli web sitesi; uzman danışmanlar ve son teknoloji alt yapısı ile ayrıcalıklı yatırım yapmak isteyenleri bekliyor."
    },
]
export default AnnouncementModel