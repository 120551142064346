import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { useTranslation } from 'react-i18next';

function AboutUs(props) {
  const { t } = useTranslation();

  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="img">
            <img src="temp/placard-hakkimizda.jpg" alt="Hakkımızda" />
          </div>
          <div className="text-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <h2 className="page-title">{t("Hakkımızda")}</h2>
                  <p>{t("Ulusararası kaldıraçlı piyasalarda uzman olan RukaFX, forex piyasasının bütün avantajlarını yatırımcılarına 5 gün 24 saat hızlı platformu ve profesyonel kadrosu ile sunmaktadır. Uzun süredir yatırım piyasalarında faaliyet gösteren RukaFX, yatırımcı odaklı bir kurum olmakla beraber güvenilir ve kurumsal hizmetler sunmaktadır.")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-main" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container" style={props.language === "fa" ? { direction: "rtl", textAlign: "right" } : {}}>
            <div className="page-content">
              <h2 className="page-title" style={props.language === "fa" ? { direction: "rtl", textAlign: "right" } : {}}>{t("Biz Kimiz")}</h2>
              <p className="mt-5">{t("2017 yılından bu yana finansal piyasalarda aracı kurum görevini üstlenen Londra merkezli RukaFX, yüksek müşteri memnuniyetini ön planda tutarak yatırımcılarına hizmet sunmaktadır.")}</p>
              <p className="mt-4">{t("RukaFX yüksek kaldıraç, düşük spread oranları ile bir forex yatırımcısının istediği bütün şartları tek bir platformda toplayan ve geniş ürün yelpazesinde işlemler sunan uluslararası bir forex firmasıdır. RukaFX'in alanında uzman kadrosu, yatırım piyasalarını 5 gün 24 saat analiz etmekte ve yatırımcılara isabetli analizler vermektedir. Profesyonel ekibimiz ile yatırım piyasalarında asla kendinizi yalnız hissetmeyeceksiniz. Birden çok yatırım yöntemini kullanarak kolay para yatırın, kolay para çekin ve yüksek kaldıraç düşük spreadlerle piyasalardan siz de payınızı alın.")}</p>
              <p className="mt-4">rukafx.com {t("BTCRuka Investment Ltd'ye aittir ve onun tarafından işletilmektedir.")}</p>
              <div className="content-img right stretch">
                <img src="temp/hakkimizda-1.jpg" alt="Title" />
              </div>
              <div className="row">
                <div className="col-12 col-md-4">

                  <h3 className="page-title text-md">{t("Neden RUKAFX.com?")}</h3>
                </div>
                <div className="col-12 col-md-8">
                  <p>{t("RUKAFX’i tercih etmeniz için onlarca sebepten bazıları;")}</p>
                  <ul>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Profesyonel Yatırım Hizmetleri")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Rekabetçi Spread/Swap Oranları")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Kriptoda 7/24, FX'de 5/24 Fiyat Akışı")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("5/24 Canlı Destek")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Kredi kartı, Coinler, Havale/Eft ve Daha Bir Çok Yöntem ile Para Yatırma/Çekme İmkanı")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Swaplı,Swapsız Hesap Türleri")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Her Yatırıma Bonus Fırsatı")}</li>
                    <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Lot İadesi")}</li>
                  </ul>
                  <p>{t("Ve dahası …")}</p>
                </div>
              </div>
              <div className="mb-5" />
              {/* <div className="row">
                  <div className="col-12 col-md-4">
                    <h3 className="page-title text-md">Misyon</h3>
                  </div>
                  <div className="col-12 col-md-8">
                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:</p>
                  </div>
                </div> */}
              <div className="mb-5" />
              {/* <div className="row">
                  <div className="col-12 col-md-4">
                    <h3 className="page-title text-md">Vizyon</h3>
                  </div>
                  <div className="col-12 col-md-8">
                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:</p>
                  </div>
                </div> */}
            </div>
          </div>
        </section>
        <StartToday />
        <Footer />
        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
    language: state.language.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs); 