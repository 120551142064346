import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { setForm } from "../../store/form";
import PromotionsModel from "../../model/PromotionsModel"
import { useTranslation } from 'react-i18next';

function Promotion(props) {
  const { t } = useTranslation();
  const promotionLink = props.location.search.replace("?p=", "")
  if(!promotionLink){
    window.location.href = '/404';

  }
  return (
    <div>
      {
        PromotionsModel.map((item, index) => {
          if (item.link === promotionLink) {
            return (
              <title key={"title" + index}>RUKAFX - {t(item.title)}</title>
            );
          } else {
            return null
          }
        })
      }
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard" style={props.app ? { filter: "blur(2px)" } : {}}>
          <div className="img">
            <img src="temp/placard-komisyonlar.jpg" alt="Komisyonlar" />
          </div>
          <div className="text-container">
            <div className="container"  style={ props.language === "fa" ? { direction : "rtl", textAlign: "right"} : {}}>
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  {
                    PromotionsModel.map((item, index) => {
                      if (item.link === promotionLink) {
                        return (
                          <h2 key={item.title + index} className="page-title">{t(item.title)}</h2>
                        );
                      } else {
                        return null
                      }
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-main" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container" style={ props.language === "fa" ? { direction : "rtl", textAlign: "right"} : {}}>
            <div className="page-content">
            {
                PromotionsModel.map((item, index) => {
                  if (item.link === promotionLink) {
                    return (
                      <div className="row" key={"Promotion-" + index} >
                        <div className="col-xl-7 col-md-7 col-xs-12">
                          <p>{t("Kampanya Kuralları")}</p>
                          <ul>
                            {
                              item.description.map((list, index) => {
                                return (<li style={props.language === "fa" ? {backgroundPosition: "right", paddingRight: "40px"} : {}}>{t(list)}</li>);
                              })
                            }
                          </ul>
                        </div>
                        <div className="col-xl-5 col-md-5 col-xs-12" style={{ textAlign: "center" }}>
                          <img src={item.src} style={{ borderRadius: "10%" }} alt={item.src} />
                        </div>
                      </div>
                    );
                  } else {
                    return null
                  }

                })
              }
            </div>
          </div>
        </section>
        <StartToday />
        <Footer />
        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
    form: state.form.form,
    language: state.language.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
    setForm: (form) => dispatch(setForm(form)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Promotion);