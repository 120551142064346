const InformationDirectoryModel = [
    {
        title:"RukaFX Güvenilir mi?",
        description:"RukaFX yatırımcılarına ve yatırımcı adaylarına şeffaf, hızlı ve kapsamlı hizmet avantajı sunmaktadır. Sürekli gelişen yazılımsal altyapısı ile varlıklarınız çevrim içi ve çevrim dışı çok sayıda sistem ile korunmaktadır."
    },
    {
        title:"Forex’in Avantajları Nelerdir?",
        description:"Blok zinciri, bir ağdaki işlemlerin daimi bir şekilde kaydedilmesini sağlar. Sistem veri tabanına benzer ancak geleneksel uçtan uca yerine merkezi olmayan bir hesap defteri kullanarak ağdaki her bir katılımcının kendilerine ait bir hesap defteri kopyasına sahip olmalarını ve tüm işlemleri görebilmelerini sağlar. Her bir blok, bir önceki blokla ilişkilendirilmiş ve şifrelenmiş bir referansla korunmaktadır, bu nedenle sisteme saldırıda bulunmak ya da hacklemek oldukça zordur."
    },
    {
        title:"Fοrеx’te Yatırım Yapabilecek Ürünler Nelerdir?",
        description:"Fοrеx işlemlerinde para birimlerinden emtialara ve endekslere kadar 700'e yakın üründe işlem yapılabilmektedir. Gerçek hesapta işlem yapmak isteyen yatırımcılar; Türk Lirası, Dolar ve EURO cinsinden bakiye yüklemesi yaparak, ulusal para birimlerinin (Döviz) yanı sıra Altın, Gümüş, Petrol, Çelik, Gaz gibi Emtialar ve Dünya Borsalarındaki hisse senetleri üzerinden yatırım yapmaya başlayabilirler."
    },
    {
        title:"Gerçek Fοrеks Hesap Özellikleri Nedir?",
        description:"Gerçek hesap ile %100 oranda aynı olan demo hesapta işlemler yapıp belli bir tecrübe ve bilgiye ulaştıktan sonra gerçek hesaba geçtiğinizde pek çok ayrıcalıktan yararlanabileceksiniz. Demo Fοrеx Hesabı ile belli bir tecrübeye ulaştıktan sonra Gerçek Hesap’a geçtiğinizde hoşgeldin bonusu haricinde her yatırımınıza çeşitli VIP  promosyonlardan faydalanacaksınız. Fοrеx yatırımınızdan elde ettiğiniz kazancı işlemin tamamlanması sonrasında anında banka hesabınıza aktarabilmek RukaFX ile mümkün."
    },
    {
        title:"Fοrеx Piyasalarında İşlem Saatleri Nedir?",
        description:"Fοrеx piyasası uluslararası bir piyasa olduğundan haftanın 5 günü (Pazartesi,Salı,Çarşamba,Perşembe,Cuma) 24 saat işlem yapabilme imkanınız mevcuttur. Fοrеx Piyasası Pazar günü Tsi 00.00'da açılır ve Cuma günü 23.59'a kadar işlem yapmaya devam edebilirsiniz. ABD piyasasının açılış saati Türkiye saati ile 16.30’dur. Amerika Birleşik Devletleri piyasası Türkiye saati ile gece 12.00’ye kadar işlem görür. Ardından yine Yeni Zelanda piyasası açılır.Cuma gecesi ABD piyasasının kapanmasıyla Fοrеx piyasaları tatile girer."
    },
    {
        title:"Spread Oranı Nedir?",
        description:"Spread bir döviz kurunun alış ve satış fiyatları arasındaki farktır. Satış fiyatı, bir para biriminin satıldığı andaki oranını, alış fiyatı ise alınmak istendiği andaki fiyatını ifade eder. RukaFX sizlere onlarca piyasa enstrümanıyla en dar ve sabit spread avantajlarıyla işlem olanağı sunuyor. Spread oranlarımızı görmek ve detaylı bilgi almak için lütfen müşteri temsilcilerimiz ile iletişime geçin."
    }
]
export default InformationDirectoryModel