import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'tr',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      tr: {
        translation: {
          header: {
            login: "GİRİŞ YAP",
            createAccount: "HESAP OLUŞTUR",
            webTrader: "WebTrader",
            menu: "MENÜ"
          }
        }
      },
      en: {
        translation: {
          header: {
            login: "LOGIN",
            createAccount: "CREATE ACCOUNT",
            menu: "MENU",
            webTrader: "WebTrader",
          },
          "BTCRuka Investment Ltd'ye aittir ve onun tarafından işletilmektedir.": "is owned & operated by BTCRuka Investment Ltd.",
          "RukaFX - Güvenilir Global Yatırım Ortağınız": "RukaFX - Your Trusted Global Investment Partner.",
          "404 Sayfa Bulunamadı": "404 Page Not Found",
          "Sayfa Bulunamadı !": "Page Not Found !",
          "Ulaşmaya çalıştığınız sayfa yayında değil veya link adresi değiştirilmiş olabilir. Anasayfaya dönmek için butona tıklayabilirsiniz.": "The page you are trying to reach is not live or the link address may have been changed. You can click the button to return to the homepage.",
          "ANASAYFAYA DÖN": "RETURN TO HOMEPAGE",
          "Bir Hata Oluştu!": "Something went wrong!",
          "Lütfen sayfayı yenileyip tekrar deneyin.": "Please refresh the page and try again.",
          "Üyelik İsteğiniz Alındı!": "Your Membership Request Received!",
          "Üyeliğinizi Aktif Etmek İçin Lütfen E-mail Kutunuzu Kontrol Ediniz.": "Please check your e-mail box to activate your membership.",
          "5/24 Canlı Destek ve Küresel Piyasalarda Yatırım Avantajı": "24/5 Live Support and Investment Advantage in Global Markets",
          "En Hızlı Para Yatırma ve Çekme İşlemleri": "Fastest Deposits and Withdrawals",
          "Güvenli ve Avantajlı Kripto Para Yatırımı": "Safe and Advantageous Cryptocurrency Investment",
          "RukaFX ile Altın, Döviz, ve Hisse Senetleri Alın-Satın": "Trade Gold, Currency, and Stocks with RukaFX",
          "Ücretsiz Hesap Oluşturun": "Create Free Account",
          "Gerçek Hesap Oluşturun": "Create Real Account",
          "Demo Hesap Oluşturun": "Create Demo Account",
          "Adınız": "Name",
          "Soyadınız": "Surname",
          "E-Posta Adresiniz": "E-mail Address",
          "Telefon Numaranız": "Telephone No.",
          "Kişisel Verilerin Korunması, İşlenmesine Dair Sözleşme ve Aydınlatma Metni’ni kabul ediyorum.": "I accept the Agreement on the Protection and Processing of Personal Data and the Clarification Text.",
          "HESAP OLUŞTUR": "CREATE AN ACCOUNT",
          "Sadece 3 Adımda Yatırım Yapmaya Başlayın!": "Start Investing in Just 3 Steps!",
          "Evrak gerekmeden; hızlı , kolay ve güvenli bir şekilde işlem yap!": "No paperwork required; trade quickly, easily and securely!",
          "Kayıt Formunu Doldurun": "Fill in the Registration Form",
          "Onay İşlemini Tamamlayın": "Complete the Confirmation Process",
          "Alış/Satış Yapmaya Başlayın": "Start Trading",
          "HAZIRSAN BAŞLAYALIM!": "LET'S START IF YOU ARE READY!",
          "Neden RUKAFX": "Why RUKAFX",
          "Yatırım dünyasında sınırlara takılmadan işlem yapabilme özğürlüğü RukaFX’te!": "RukaFX offers freedom to trade without limits in the investment world!",
          "Ücretsiz Eğitim": "Free Training",
          "Profesyonel eğitmenlerimizden telefonla ve ücretsiz olarak bire bir eğitim alarak yatırım hayatınıza bugün başlayabilirsiniz.": "You can start your investment life today by getting one-on-one training from our professional trainers over the phone and free of charge.",
          "Hızlı Platform": "Fast Platform",
          "Modern ve teknolojik altyapımız ve sunucu desteğimiz ile hızlı ve güvenli işlem amaçlıyoruz.": "We aim for fast and secure transactions with our modern and technological infrastructure and server support.",
          "Lisanslı İşlem Platformu": "Licensed Trading Platform",
          "5/24 dünya ekonomisine yön veren para piyasalarında yatırım yapabilmeniz için lisanslı işlem platformumuz her an elinizin altında.": "Our licensed trading platform is always at your fingertips so you can invest in the money markets that shape the world economy 24/5.",
          "Sıfır Risk ile Yatırımı Öğrenin": "Learn to Invest with Zero Risk",
          "Yatırım işlemlerinize başlamadan önce ücretsiz olarak açacağınız Demo Hesap’ta 100.000 USD deneme bakiyesi ile işlem yapmayı risk almadan öğreneceksiniz.": "Before starting your investment transactions, you will learn how to trade with a trial balance of $ 100,000 in the Demo Account available for free of charge.",
          "Kaldıraçlı İşlem": "Leveraged Trading",
          "Depozit olarak düşük miktarlarla yüksek pozisyonlar alınmasını sağlayarak yatırımdan en yüksek kârın elde edilmesini kolaylaştıran bir araçtır.": "It is an instrument that facilitates obtaining the highest profit from the investment by providing high positions with low amounts as a deposit.",
          "Çift Yönlü İşlem": "Dual Trading",
          "Yatırım araçlarının fiyatlarından hem düşerken hem de yükselirken para kazanabilirsiniz.": "You can earn money while the prices of investment instruments are both falling and rising.",
          "RUKAFX Bilgi Rehberi": "RUKAFX Knowledge Guide",
          "Bilgi Rehberi": "Knowledge Guide",
          "RukaFX Güvenilir mi?": "Is RukaFX trustworthy ?",
          "RukaFX yatırımcılarına ve yatırımcı adaylarına şeffaf, hızlı ve kapsamlı hizmet avantajı sunmaktadır. Sürekli gelişen yazılımsal altyapısı ile varlıklarınız çevrim içi ve çevrim dışı çok sayıda sistem ile korunmaktadır.": "RukaFX offers its investors and investor candidates the advantage of transparent, fast and comprehensive service. With its constantly developing software infrastructure, your assets are protected by several online and offline systems.",
          "Forex’in Avantajları Nelerdir?": "What are the advantages of forex?",
          "Blok zinciri, bir ağdaki işlemlerin daimi bir şekilde kaydedilmesini sağlar. Sistem veri tabanına benzer ancak geleneksel uçtan uca yerine merkezi olmayan bir hesap defteri kullanarak ağdaki her bir katılımcının kendilerine ait bir hesap defteri kopyasına sahip olmalarını ve tüm işlemleri görebilmelerini sağlar. Her bir blok, bir önceki blokla ilişkilendirilmiş ve şifrelenmiş bir referansla korunmaktadır, bu nedenle sisteme saldırıda bulunmak ya da hacklemek oldukça zordur.": "Blockchain provides a permanent recording of transactions in a network. It is similar to a system database but uses a decentralized ledger instead of the traditional end-to-end ledger, allowing each participant in the network to have their own copy of the ledger and be able to see all transactions. Each block is protected by an encrypted reference associated with the previous block, so it is very difficult to attack or hack the system.",
          "Fοrеx’te Yatırım Yapabilecek Ürünler Nelerdir?": "What are the products to invest in fοrеx?",
          "Fοrеx işlemlerinde para birimlerinden emtialara ve endekslere kadar 700'e yakın üründe işlem yapılabilmektedir. Gerçek hesapta işlem yapmak isteyen yatırımcılar; Türk Lirası, Dolar ve EURO cinsinden bakiye yüklemesi yaparak, ulusal para birimlerinin (Döviz) yanı sıra Altın, Gümüş, Petrol, Çelik, Gaz gibi Emtialar ve Dünya Borsalarındaki hisse senetleri üzerinden yatırım yapmaya başlayabilirler.": "In fοrеx transactions, nearly 700 products can be traded, from currencies to commodities and indices. Investors who want to trade in the real account can start to invest in commodities such as gold, silver, oil, steel, gas and stocks in the world stock exchanges, as well as national currencies (foreign currency), by adding money to their account in Turkish Lira, US Dollar and EURO.",
          "Gerçek Fοrеks Hesap Özellikleri Nedir?": "What are real forex account features?",
          "Gerçek hesap ile %100 oranda aynı olan demo hesapta işlemler yapıp belli bir tecrübe ve bilgiye ulaştıktan sonra gerçek hesaba geçtiğinizde pek çok ayrıcalıktan yararlanabileceksiniz. Demo Fοrеx Hesabı ile belli bir tecrübeye ulaştıktan sonra Gerçek Hesap’a geçtiğinizde hoşgeldin bonusu haricinde her yatırımınıza çeşitli VIP  promosyonlardan faydalanacaksınız. Fοrеx yatırımınızdan elde ettiğiniz kazancı işlemin tamamlanması sonrasında anında banka hesabınıza aktarabilmek RukaFX ile mümkün.": "You will be able to benefit from many privileges when you switch to the real account after making transactions on the demo account, which is 100% the same as the real account, and after gaining a certain experience and knowledge. You will benefit from various VIP promotions for every investment except the welcome bonus when you switch to the real account after you have gained a certain experience with the demo fοrеx account. It is possible with RukaFX to instantly transfer the earnings from your fοrеx investment to your bank account after the completion of the transaction.",
          "Fοrеx Piyasalarında İşlem Saatleri Nedir?": "What are the trading hours in the forex markets?",
          "Fοrеx piyasası uluslararası bir piyasa olduğundan haftanın 5 günü (Pazartesi,Salı,Çarşamba,Perşembe,Cuma) 24 saat işlem yapabilme imkanınız mevcuttur. Fοrеx Piyasası Pazar günü Tsi 00.00'da açılır ve Cuma günü 23.59'a kadar işlem yapmaya devam edebilirsiniz. ABD piyasasının açılış saati Türkiye saati ile 16.30’dur. Amerika Birleşik Devletleri piyasası Türkiye saati ile gece 12.00’ye kadar işlem görür. Ardından yine Yeni Zelanda piyasası açılır.Cuma gecesi ABD piyasasının kapanmasıyla Fοrеx piyasaları tatile girer.": "Since the forex market is an international market, you can trade five days a week (Monday, Tuesday, Wednesday, Thursday, and Friday), 24 hours a day. The fοrеx market opens at 00.00 on Sunday (local time in Turkey) and you can continue trading until 23.59 on Friday (local time in Turkey). The opening time of the US market is 16.30 at local time in Turkey. The United States market is traded until 24.00 at local time in Turkey. Then the New Zealand market opens. With the closing of the US market on Friday night, the forex markets are closed for holiday.",
          "Spread Oranı Nedir?": "What is spread rate?",
          "Spread bir döviz kurunun alış ve satış fiyatları arasındaki farktır. Satış fiyatı, bir para biriminin satıldığı andaki oranını, alış fiyatı ise alınmak istendiği andaki fiyatını ifade eder. RukaFX sizlere onlarca piyasa enstrümanıyla en dar ve sabit spread avantajlarıyla işlem olanağı sunuyor. Spread oranlarımızı görmek ve detaylı bilgi almak için lütfen müşteri temsilcilerimiz ile iletişime geçin.": "Spread is the difference between the buying and selling prices of an exchange rate. The selling price refers to the rate of a currency at the time it is sold, and the buying price refers to the price at the time it is wanted to be bought. RukaFX offers you the opportunity to trade with the narrowest and fixed spread advantages with dozens of market instruments. Please contact our customer representatives to see our spread rates and get detailed information.",
          "RUKAFX ile Işık Hızında Forex Ve Kripto Para İşlemleri": "Lightning-Speed Forex and Cryptocurrency Transactions with RUKAFX",
          "İnternetten yatırım teknolojisinin nimetlerinden faydalanmak için en hızlı teknolojiyi tercih edin. En son teknoloji Ruka Yatırım Platformunu indirip yatırım işlemlerine başlayın!": "Prefer the fastest technology to benefit from the benefits of internet investment technology. Download the latest technology Ruka Investment Platform and start trading!",
          "Hemen Deneyimle": "Experience Now",
          "Şimdi İndirin": "Download Now",
          "RUKAFX ile bugün Başlayın!": "Get Started with RUKAFX today!",
          "RUKAFX, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. RUKAFX ile yatırım piyasalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.": "RUKAFX offers its customers low commission principles as well as a reliable and transparent service approach. With RUKAFX, you can maximize your trading experience in investment markets.",

          "Hakkımızda": "About Us",
          "Kurumsal": "Corporate",
          "Ulusararası kaldıraçlı piyasalarda uzman olan RukaFX, forex piyasasının bütün avantajlarını yatırımcılarına 5 gün 24 saat hızlı platformu ve profesyonel kadrosu ile sunmaktadır. Uzun süredir yatırım piyasalarında faaliyet gösteren RukaFX, yatırımcı odaklı bir kurum olmakla beraber güvenilir ve kurumsal hizmetler sunmaktadır.": "RukaFX, which is an expert in international leveraged markets, offers all the advantages of the forex market 5/24 to its investors with its fast platform and professional staff. RukaFX, which has been operating in the investment markets for a long time, is an investor-oriented institution and offers reliable and institutional services.",
          "Biz Kimiz": "Who We Are",
          "2017 yılından bu yana finansal piyasalarda aracı kurum görevini üstlenen Londra merkezli RukaFX, yüksek müşteri memnuniyetini ön planda tutarak yatırımcılarına hizmet sunmaktadır.": "London-based RukaFX, which has been acting as a brokerage house in the financial markets since 2017, provides services to its investors by prioritizing high customer satisfaction.",
          "RukaFX yüksek kaldıraç, düşük spread oranları ile bir forex yatırımcısının istediği bütün şartları tek bir platformda toplayan ve geniş ürün yelpazesinde işlemler sunan uluslararası bir forex firmasıdır. RukaFX'in alanında uzman kadrosu, yatırım piyasalarını 5 gün 24 saat analiz etmekte ve yatırımcılara isabetli analizler vermektedir. Profesyonel ekibimiz ile yatırım piyasalarında asla kendinizi yalnız hissetmeyeceksiniz. Birden çok yatırım yöntemini kullanarak kolay para yatırın, kolay para çekin ve yüksek kaldıraç düşük spreadlerle piyasalardan siz de payınızı alın.": "RukaFX is an international forex company that combines high leverage, low spread rates, and all the conditions desired by a forex investor in a single platform, and offers a wide range of transactions. RukaFX's expert staff analyzes investment markets 5/24, and provides accurate analysis to investors. With our professional team, you will never feel alone in the investment markets. Using multiple investment methods, you can deposit easily, withdraw easily and get your share of the markets with high leverage and low spreads.",
          "Neden RUKAFX.com?": "Why RUKAFX.com?",
          "RUKAFX’i tercih etmeniz için onlarca sebepten bazıları;": "Some of the dozens of reasons to prefer RUKAFX;",
          "Profesyonel Yatırım Hizmetleri": "Professional Investment Services",
          "Rekabetçi Spread/Swap Oranları": "Competitive Spread/Swap Rates",
          "Kriptoda 7/24, FX'de 5/24 Fiyat Akışı": "24/7 Price Flow on Crypto, 24/5 Price Flow on FX",
          "5/24 Canlı Destek": "24/5 Live Support",
          "Kredi kartı, Coinler, Havale/Eft ve Daha Bir Çok Yöntem ile Para Yatırma/Çekme İmkanı": "Deposit/Withdrawal Opportunity with Credit Card, Coins, Wire Transfer/EFT and More",
          "Swaplı,Swapsız Hesap Türleri": "Swap and Non-Swap Account Types",
          "Her Yatırıma Bonus Fırsatı": "Bonus Opportunity for Every Investment",
          "Lot İadesi": "Lot Refund",
          "Ve dahası …": "And more …",

          "Spread Oranları": "Spread Rates",
          "RukaFX rekabetçi spread oranlarımıza aşağıdaki tablodan ulaşabilirsiniz.": "You can find our RukaFX competitive spread rates in the table below.",

          "RukaFX Lisansını Görüntüle": "View RukaFX License",
          "ASIC Sertifikası": "ASIC Certificate",

          "Duyurular": "Announcements",
          "RUKAFX ile ilgili tüm güncel duyuruları bu kısımdan takip edebilirsiniz.": "You can follow all the current announcements about RUKAFX from this section.",
          "RukaFX Türkçe Dil Desteği Vermeye Başladı!": "RukaFX Started to Provide Turkish Language Support!",
          "5 ayrı ülkede yatırımcılara hizmet veren RukaFX; Türkçe dil desteği hizmetine başladı! Türk yatırımcılara özel olarak hazırlanan RukaFX Türkçe dil destekli web sitesi; uzman danışmanlar ve son teknoloji alt yapısı ile ayrıcalıklı yatırım yapmak isteyenleri bekliyor.": "RukaFX, which serves investors in 5 different countries, has started its Turkish language support service! Specially prepared for Turkish investors, RukaFX is waiting for those who want to make privileged investments with its Turkish language supported website, expert advisors and state-of-the-art infrastructure.",

          "İletişim": "Contact Us",
          "Aklınıza takılan her türlü soru, görüş ve önerileriniz için bizimle iletişime geçebilirsiniz.": "You can contact us for any questions you might have, as well as for your opinions and suggestions.",
          "Mesaj": "Message",
          "GÖNDER": "SUBMIT",
          "Mesajınız Alındı!": "Your Message Received!",
          "Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.": "Our team will contact you as soon as possible.",

          "Döviz Çiftleri": "Currency Pairs",
          "Döviz, Türkçe’de yabancı ülkelerin paralarına verilen genel isimdir. Parite ise bir ülkenin para biriminin başka bir ülkenin para birimine karşı değeridir. Paritede ilk yazılan para birimi baz (ana) para birimini ifade eder. İkinci yazılan para birimi ise karşıt para birimini ifade etmektedir.": "Currency is the general name given to the currencies of foreign countries in Turkish. Parity is the value of one country's currency against another country's currency. The first currency in the parity represents the base (main) currency. The second currency is the opposite currency.",
          "RUKAFX ile döviz çiftleri arasında alım-satım işlemleri yaparak kazanç elde edebilirsiniz. Uzman analist ekibimiz tüm yatırım fırsatlarında yanınızda olacaktır.": "With RUKAFX, you can earn profits by trading between currency pairs. Our expert analyst team will be with you in all investment opportunities.",

          "CFD İşlemleri": "CFD Trading",
          "Dünyanın önde gelen birçok şirketine yatırım ayrıcalığı artık RUKAFX’te!": "The privilege of investing in many of the world's leading companies is now at RUKAFX!",
          "Hisse senedi; bir şirketin sermayesinin eş değer parçalarından birini ifade eder. Bir şirkete ait hisse senedi satın alarak, o şirketin ortakları arasına katılmış olursunuz. Hisse senedi yatırım yaparken satın alacağınız şirketin faaliyetleri, borçluluk durumu, büyüme beklentileri, kar durumu, temettü (kar payı) miktarı kısaca şirketle ilgili her şeyi iyice araştırmalısınız. Başarılı, uzun yıllar faaliyet gösteren ve kar elde eden şirketleri seçerek hisse senedi yatırımdan kazanç sağlayabilirsiniz.": "A stock represents one of the equivalent parts of a company's capital. By purchasing stock of a company, you become one of the partners of that company. When investing in stocks, you should thoroughly research everything about the company's activities, indebtedness, growth expectations, profit status, dividend (profit share) amount, briefly. You can profit from stock investment by choosing companies that are successful, operating for many years and making profits.",
          "Hisse yatırımlarınızı artık yurt içi ile sınırlamak zorunda değilsiniz. RUKAFX ile çeşitli sektörlerde dünyanın önde gelen şirketlerinde haftanın 5 günü boyunca alış - satış işlemi gerçekleştirebilirsiniz. (Apple, Amazon, Facebook, Netflix, Tesla vb.) Bu sayede dünya piyasalarına kolayca ulaşabilir ve uluslararası şirketlerin hisse senetlerini kısa, orta ya da uzun vadede alıp satabilirsiniz.": "You no longer have to limit your stock investments to domestic ones. With RUKAFX, you can buy and sell 5 days a week in the world's leading companies in various sectors (Apple, Amazon, Facebook, Netflix, Tesla, etc.) In this way, you can easily access world markets and buy and sell the stocks of international companies in the short, medium or long term.",

          "Emtialar": "Commodities",
          "Ticarete konu olan mallara genel olarak emtia denilmektedir. Emtialar dünya ticaretinde alınıp satılan, ekonomilere ve güncel yaşama yön veren en önemli ticaret araçlarıdır. Emtia ürünlerinin fiyatları arz ve talebe göre oluşmaktadır. Bu yüzden alım satıma konu olan malın kullanım alanı, arz olanakları, güncel talebi ya da kullanıldığı alanlar ön plana çıkabilmektedir.": "Goods that are subject to trade are generally called commodities. Commodities are the most important trade tools that are bought and sold in world trade and that shape economies and current life. Prices of commodity products are determined by supply and demand. For this reason, the usage area, supply possibilities, current demand or areas of use of the goods subject to purchase and sale can come to the fore.",
          "Emtia ürünlerinin üretim koşulları, talep düzeyi ve ham madde olanakları fiyat oluşumunda dikkate alınan faktörler arasındadır. Bu durum birçok yatırımcının dikkatini çekmiş ve emtialar yatırımların yoğunlaştığı enstrümanlardan biri haline gelmiştir. Platformumuzda ulaşabileceğiniz emtialar piyasa koşullarında en çok talep gören ürünlerdir.": "Production conditions of commodity products, demand level and raw material possibilities are among the factors taken into account in price formation. This situation has attracted the attention of many investors and commodities have become one of the instruments in which investments are concentrated. The commodities you can access on our platform are the most demanded products in market conditions.",
          "RUKAFX platformu üzerinden altın, gümüş, petrol, bakır ve doğalgaz ürünlerinin tamamında rekabetçi fiyatlarla hizmet sağlamaktayız.": "We provide services at competitive prices for all gold, silver, oil, copper and natural gas products through the RUKAFX platform.",

          "Forex": "Forex",
          "Forex Nedir?": "What is Forex?",
          "Forex veya kısa ismiyle FX, ingilizce ‘Foreign Exchange’ sözcüklerinin kısaltmasıdır. Forex piyasası da farklı ülke para birimlerinin, birbirlerine karşı görece değerlerinin, kaldıraçlı olarak alınıp satılmasına olanak sağlayan tezgahüstü bir döviz piyasasıdır.": "Forex, or FX for short, is an abbreviation of the English words “Foreign Exchange”. Forex market is an over-the-counter foreign exchange market that allows the relative values of different countries' currencies to be bought and sold with leverage.",
          "Merkez bankalarından, uluslararası finans kuruluşlarına, serbest fonlara ve dünyanın her yerinden bireysel yatırımcılara kadar çok geniş bir yatırımcı kitlesine sahip olan Forex piyasası, bu yönüyle dünyanın en likit ve en hacimli (günlük ortalama 5 trilyon dolar) finansal piyasasıdır. Ayrıca Forex piyasaları 5 gün boyunca 24 saat açık olması nedeniyle de dünyanın farklı noktalarından gelebilecek veri akışına anında tepki verebilmektedir.": "The Forex market, which has a wide investor base from central banks, international financial institutions, hedge funds and individual investors from all over the world, is the most liquid and voluminous (daily average of 5 trillion dollars) financial market in the world. Moreover, since forex markets are open 24 hours a day for five days, they can instantly react to data flows from different parts of the world.",

          "DEMO HESAP AÇ": "OPEN A DEMO ACCOUNT",
          "FOREX KAMPANYALARI": "FOREX CAMPAIGNS",

          "Demo Hesap Aç": "Open a Demo Account",
          "Gerçek Hesap Aç": "Open a Real Account",

          "Tüm Hakları Saklıdır.": "All Rights Reserved.",
          "Daha Fazla...": "More...",

          "Giriş Yap": "Login",
          "Hesap Oluştur": "Create Account",
          "WebTrader": "WebTrader",

          "Promosyonlar": "Promotions",
          "Kampanya Kuralları": "Campaign Rules",
          "%20 Hoşgeldin Bonusu": "20% Welcome Bonus",
          "RUKAFX’te gerçek hesap açtıktan sonra ilk yatırımınızda geçerlidir.": "It is valid for your first investment after opening a real account at RukaFX.",
          "Kazanılan bonus miktarı yapılacak forex işlemlerinde marjin seviyesini yükseltmek için takviye görevi görür, çekilemez. Fakat bonus kullanılarak elde edilen kâr çekilebilir. Bonus alındıktan sonra stop out olunması durumunda, yatırımcının stop out olduktan sonra hesabında kalan meblağ almış olduğu bonus miktarı veya daha azı kadar ise, hesabında kalan meblağ almış olduğu bonusa eşdeğer sayılacağı için bu miktar için çekim talebinde bulunulamaz. Örneğin; 1.000 $ yatırıp %30 Yatırım Bonusu ile 300$ hoşgeldin bonusu almış olan bir yatırımcı stop out olması durumunda, hesabında 300 $ veya daha düşük bir miktar kaldığında, bu meblağ yatırımcının hesabından düşülür.": "The bonus amount earned acts as a reinforcement to increase the margin level in forex transactions, and cannot be withdrawn. But the profit made using the bonus can be withdrawn. In case of stop-out after receiving the bonus, if the amount remaining in the investor's account after the stop-out is equal to or less than the bonus amount received, the amount remaining in the account will be considered equivalent to the bonus received, and no withdrawal request can be made for this amount. For example, if an investor who deposits $1,000 and receives a 30% Deposit Bonus and a welcome bonus of $300 has a stop out, this amount will be deducted from the investor's account when there is $300 or less left in their account.",
          "Promosyona katılım için web sitemizden RUKAFX Canlı Destek Hattı veya Whatsapp üzerinden müşteri temsilcileri ile iletişime geçilmesi yeterlidir.": "To participate in the promotion, it is sufficient to contact your customer representative via the Live Support Line on the RukaFX website or via Whatsapp.",
          "RUKAFX, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.": "RukaFX reserves the right to change the rules of this promotion or cancel the promotion altogether. It is the investor's own responsibility to follow the current rules of any promotion.",
          "Maksimum alınabilir bonus miktarı 5000$’dır.": "The maximum receivable bonus amount is $5000.",
          "Bu kampanya, başka kampanya ile birleştirilemez. RUKAFX kampanya koşullarında değşiklik yapma hakkını saklı tutar.": "This promotion cannot be combined with any other promotion. RukaFX reserves the right to make changes in the campaign conditions.",
          "%10 Referans Bonusu": "10% Referral Bonus",
          "Gerçek hesap sahibi olan yatırımcılarımız şirketimize yeni yatırımcılar için referans olması durumunda ilk yatırımları üzerinden %10 bonus kampanyasına hak kazanabilir.": "Our investors who have real accounts can qualify for a 10% bonus campaign on their first investment, if they become a reference for new investors to our company.",
          "Tüm yatırımcılarımızın yeni referanslarının sadece ilk yatırımlarında geçerli olan bonus kampanyasıdır.": "It is a bonus campaign that is valid only for the first investments of all our investors' new references.",
          "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.": "The amount added to the account with the bonus is not a withdrawable amount.",
          "Referans bonusu, başka herhangi bir kampanya ile birleştirilemez.": "The referral bonus cannot be combined with any other promotion.",
          "Yatırımcılarımız elde ettikleri karlarını çekerken bonus tutarları silinmez.": "Bonus amounts are not deleted when our investors withdraw their profits.",
          "Bonus hak edişi elde edilirken yatırılmış olan fon miktarı çekilmek istenirse öncesinde bonus tutarının silinmesi gerekmektedir.": "If the deposited fund amount is desired to be withdrawn while earning the bonus, the bonus amount must be deleted beforehand.",
          "RUKAFX bu kampanyayı değiştirme veya tamamen iptal etme hakkına sahiptir. Kampanyada yapılacak herhangi bir değişikliği takip etme sorumluluğu yatırımcılarımızın kendilerine aittir.": "RukaFX has the right to change the campaign or cancel it completely. It is the responsibility of our investors to monitor any changes to be made in the campaign.",
          "Bu bonustan hak edilebilecek max. bonus tutarı 1000$’dır.": "The maximum bonus amount that can be earned from this bonus is $1000.",
          "Kayıp Sigorta Kampanyası": "Lost Insurance Campaign",
          "Kampanyadan faydalanmak için alt deposit tutarı min. 300$’dır.": "The minimum deposit amount to take advantage of the campaign is $300.",
          "Bu kampanya sadece yeni müşteriler ile hesabı stop out olan yatırımcılar için geçerlidir.": "This campaign is only valid for new customers and traders whose accounts have stopped.",
          "Yatırımcının deposit sonrası işleme başladığı gün itibari ile 3. Günün sonunda kaybı hesaplanır, bir sonraki gün kaybının yarısı iade edilir.": "As of the day the investor starts trading after the deposit, the loss is calculated at the end of the 3rd day, and half of the loss is returned the next day.",
          "Yatırımcı bu kampanyadan yararlanabilmek için major paritelerde işlem yapmalıdır.": "In order to benefit from this campaign, the investor must trade in major parities.",
          "Bu kampanyadan kazanılabilecek maksimum bonus tutarı 1000$'dır.": "The maximum bonus amount that can be earned from this campaign is $1000.",
          "Bu kampanya, başka kampanya ile birleştirilemez. RUKAFX kampanya koşullarında değşiklik yapma hakkını saklı tutar.": "This promotion cannot be combined with any other promotion. RukaFX reserves the right to change the campaign conditions.",
          "Hesabını RUKAFX’e Taşıyan Herkese %30'a Kadar Bonus Fırsatı": "Up to 30% Bonus Opportunity for Anyone Who Moves Their Account to RukaFX",
          "Hesap Taşıma Bonusu, farklı bir aracı kurumdan RUKAFX’e taşınacak olan aktif forex hesabındaki varlık (equity) miktarının %30’i kadar olup, her yatırımcı bu promosyondan yalnızca 1 kez faydalanabilir.": "Account Transfer Bonus is 30% of the amount of assets (equity) in the active forex account to be transferred to RukaFX from a different brokerage house, and each investor can benefit from this promotion only once.",
          "%30 Hesap Taşıma Bonusu’ndan kazanılabilecek maksimum bonus miktarı 3000$’dır.": "The maximum bonus amount that can be earned from the 30% Account Move Bonus is $3000.",
          "RUKAFX, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.": "RukaFX reserves the right to change the rules of this promotion or cancel the promotion altogether. It is the investor's own responsibility to follow the current rules of any promotion.",
          "Ana paranızın %80 'ini çektiğiniz taktirde ana paranızla almış olduğunuz bonus hesabınızdan düşülücektir.": "If you withdraw 80% of your principal, the bonus you have received with your principal will be deducted from your account.",
          "Kullanıcının kural dışı faaliyetlerde bulunduğu ya da hileye başvurduğu tespit edilir ise hiçbir mazeret belirtilmeden RUKAFX hesabı kapatılacaktır.": "If it is determined that the user has engaged in illegal activities or resorted to cheating, RukaFX account will be closed without any excuse.",
          "%30 Kripto Para Yatırımına Özel Hoşgeldin Bonusu": "30% Cryptocurrency Investment Welcome Bonus",
          "Bu promosyondan ilk kez yatırımda bulunacak olan üyeler faydalanabilir.": "Members who will invest for the first time can benefit from this promotion.",
          "Bu promosyondan faydalanabilmesi için yatırımcının hesabına fonlamayı kabul edilen kripto paralar ile gerçekleştirmesi gerekmektedir.": "In order to benefit from this promotion, the investor must fund his account with accepted cryptocurrencies.",
          "Kazanılan bonus miktarı marjin seviyenizi yükseltmek için destek niteliği taşır, çekilemez. Fakat bonus kullanılarak sağlanan kazanç çekilebilmektedir.": "The amount of bonus earned is a support to increase your margin level, it cannot be withdrawn. However, the winnings using the bonus can be withdrawn.",
          "Promosyona katılmak için canlı destek hattı veya tarafınıza özel atanan müşteri temsilciniz ile iletişime geçmeniz yeterlidir.": "To participate in the promotion, it is sufficient to contact the live support line or your customer representative specially assigned to you.",
          "Yatırımcı çekim talebi verdiğinde bonus hesabından silinmektedir.": "When the investor makes a withdrawal request, the bonus is deleted from his account.",
          "RUKAFX bu promosyonu değiştirme veya iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.": "RukaFX reserves the right to change or cancel this promotion. It is the investor's own responsibility to follow the current rules of any promotion.",
          "%20 Stop Out Bonusu": "%20 Stop Out Bonus",
          "%10 Yatırım Bonusu": "10% Deposit Bonus",
          "RUKAFX’te yapacağınız her yatırıma %10 bonus kazanın!": "Get 10% bonus on every investment you make at RukaFX!",
          "Her yatırımcı her para yatırma işlemi için bu kampanyadan yararlanabilir.": "Every investor can benefit from this campaign for every deposit.",
          "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.": "The amount added to the account with the bonus is not a withdrawable amount.",
          "Bonus kampanyasından yararlanan yatırımcılarımız başka herhangi bir kampanyadan yararlanamazlar.": "Our investors who benefit from the bonus campaign cannot benefit from any other campaign.",
          "Yatırımcılarımızın yapmış oldukları işlemlerde hesabın güçlendirilmesine ve daha yüksek oranlar ile yatırım yapmasına imkan sağlar.": "It allows our investors to strengthen the account in the transactions they have made and to invest with higher rates.",
          "Bonus hak edişi elde edilirken yatırılmış olan fon miktarı çekilmek istenirse öncesinde bonus tutarının silinmesi gerekmektedir.": "If the deposited fund amount is desired to be withdrawn while earning the bonus, the bonus amount must be deleted beforehand.",
          "Yatırımcılarımız en fazla 15.000$ alabilmektedir.": "Our investors can receive a maximum of $15,000.",

          "Gizlilik ve Güvenlik Politikası": "Privacy and Security Policy",
          "BTCRUKA ('Şirket'), geçerli yasal gerekliliklere uygun olarak Müşteriden elde edilen kişisel verilerin mahremiyetini ve gizliliğini korumayı taahhüt eder. Şirket, yalnızca Müşteri ile iş ilişkisi için gerekli olduğu ölçüde veri toplayacaktır.": "BTCRUKA (‘the Company’) is committed to protect the privacy and confidentiality of personal data obtained from Client, in compliance with applicable legal requirements. The Company shall collect data only to the extent that it is necessary for the business relationship with the Client.",
          "Bu Gizlilik ve Güvenlik Politikası, Müşterinin web sitelerimizi ziyaretleri sırasında toplanan veriler de dahil olmak üzere, Şirketin Müşteri hakkında kişisel verileri nasıl topladığını, muhafaza ettiğini, kullandığını ve ilettiğini açıklar.": "This Privacy and Security Policy explains how the Company collects, maintains, uses and transmits personal data about the Client, including data collected during the Client’s visits to our websites.",
          "Müşterinin Kişisel Verilerinin Toplanması ve Kullanımı": "Collection and Use of Client’s Personal Data",
          "Müşterinin, iş ilişkisinin kurulması, hizmetlerin sağlanması ve alım satım hesaplarına ve faaliyetlerine erişim için gerekli kişisel verileri Şirkete sağlaması gerekmektedir. Kayıt işlemi sırasında sağlanan veriler, diğerlerinin yanı sıra; Müşterinin doğum tarihi, tam adı ve soyadı, tam ikamet ve/veya iş adresi, müşterinin kimlik belgelerinde mevcut bilgiler (yani Pasaport / Kimlik Kartı), iletişim bilgileri (yani cep telefonu numarası, e-posta adresi). Bu bilgiler, Şirketin Müşteriyi tanımlamasına ve doğrulamasına, Müşteriye bir ticaret hesabı ve erişim kodu/şifresi etkinleştirmesine ve sağlamasına ve Müşteri ile iletişime geçmesine ve mevcut hizmetler, ürünler ve promosyonlar hakkında bilgi sağlamasına olanak tanır.": "Client is required to provide to the Company with personal data necessary for the establishment of the business relationship, the provision of services and access to trading account/s and activity. Data provided during the registration process might include inter alia; Client’s date of birth, full name and surname, full residential and/or business address, information available on client’s identification documents (i.e. Passport / Identification Card), contact information (i.e. mobile phone number, e-mail address). This information enables the Company to identify and verify the Client, to activate and provide the Client with a trading account and access code/password and to contact the Client and provide information on available services, products and promotions.",
          "Şirket ayrıca, Müşterinin web sitelerini, özel arayüzleri veya iletişim kanallarını, ticaret yazılımlarını veya sağlanan platformları kullanımından da bilgi alır. Bu bilgiler, ziyaret edilen site alanları, görüntülenen sayfalar, ziyaretlerin sıklığı ve süresi, İnternet Protokolü (IP) adresi, bilgisayar tanımlama parametreleri, yürütülen işlem türleri, indirilen belgeler, fare tıklamaları, fare hareketleri ve kaydırma etkinliğini içerebilir.": "The Company also obtain information from the Client’s use of its websites, dedicated interfaces or communication channels, trading software or platforms provided. This information may include site areas visited, pages viewed, frequency and duration of visits, Internet Protocol (IP) address, computer identification parameters, types of transactions conducted, documents downloaded, mouse clicks, mouse movements and scrolling activity.",
          "Şirket, müşterilerinin ihtiyaçlarını karşıladığından emin olmak için anonim istatistiksel bilgiler toplar. Şirket, bu tür verileri toplayarak mevcut ve potansiyel müşterilere sunulan hizmetleri izleyebilir ve iyileştirebilir. Şirket, bu tür bilgileri üçüncü kişilerle paylaşabilir.": "The Company collects anonymous statistical information in order to ensure that it meets the needs of its clients. By collecting such data, the Company is able to monitor and improve the services offered to existing and potential clients. The Company may share such information with third parties.",
          "Müşteri Bilgilerini Güncelleme": "Updating Client’s information",
          "Müşterinin Şirket ile olan iş ilişkisi sırasında, Müşteriden, yenilenen kimlik belgeleri, güncellenmiş iletişim bilgileri dahil olmak üzere, bu ilişkinin sürdürülmesi için gerekli olan kişisel verileri sağlaması istenebilir.": "During the Client’s business relationship with the Company, Client might be requested to provide personal data necessary for the maintenance of such relationship, including any renewed identification document, updated contact information.",
          "Ayrıca Müşteri, kişisel bilgilerdeki herhangi bir değişikliği Şirkete bildirebilir. Şirket, Müşterinin kişisel bilgilerini Müşterinin talimatlarına uygun olarak değiştirecek ve Şirketin ilgili kişisel verileri yasal veya yasal amaçlarla tutması ve/veya Müşteriye talep edilen hizmetleri sunması gerektiği durumlar dışında, önceden elde edilen bilgileri silecektir. veya iş kayıtlarını tutmak için.": "Additionally, the Client may inform the Company for any change on personal details. The Company shall change the Client’s personal information in accordance with the Client’s instructions and delete previously obtained information except to the extent that the Company is required to hold the relevant personal data for regulatory or legal purposes and/or to provide the Client with the services requested or to retain business records.",
          "Verilerin Üçüncü Kişilere Aktarılması": "Transfer of Data to Third Parties",
          "Kişisel veriler, Şirket tarafından Avrupa Ekonomik Alanında ve Avrupa Ekonomik Alanı dışında bulunan üçüncü şahıslara yalnızca bu Politikada açıklanan amaçlar doğrultusunda aktarılabilir.": "Personal data may be transferred by the Company to third parties located in European Economic Area as well as to non-European Economic Area only for the purposes described in this Policy.",
          "Üçüncü taraflar arasında Muhasebeciler, Denetçiler, Hukuk Müşavirleri, Bankalar ve diğer Kredi Kuruluşları, Ödeme Sistemleri ve Ödeme Hizmet Sağlayıcıları, Pazarlama ve Müşteri Destek Kuruluşları, Şirketin sözleşme ilişkisi içinde olduğu Hizmet Sağlayıcılar ve Şirket ile aynı Şirketler grubu içindeki kuruluşlar yer alabilir. Şirket.": "Third parties might include Accountants, Auditors, Legal Consultants, Banks and other Credit Institutions, Payment Systems and Payment Service Providers, Marketing and Customer Support Agencies, Service Providers with whom the Company maintains a contractual relationship and entities within the same group of Companies as the Company.",
          "Müşterinin kimliğinin belirlenmesi ve doğrulanması amacıyla Şirket, Müşterinin verilerini, ilgili yasal gerekliliklere uygun olarak, bu tür bir tanımlamayı gerçekleştirmeye yetkili bir üçüncü taraf hizmet sağlayıcısına iletecektir. Şirket, iletilen verilerin mahremiyetini ve gizliliğini sağlayacaktır.": "For the purposes of identification and verification of Client’s identity, the Company shall transmit Client’s data to a third-party service provider authorised to perform such identification, in compliance with relevant regulatory requirements. The Company shall ensure privacy and confidentiality of transmitted data.",
          "Şirket, herhangi bir düzenleyici kurum, kamu kurumu, mali istihbarat birimi, polis, devlet dairesi, vergi dairesi, kanun uygulayıcı kurumlar tarafından talimat verilmesi halinde veya kanunen gerekli olması halinde veya Şirket'in Müşterinin kişisel verilerini üçüncü şahıslara aktarma hakkını saklı tutar. mal ve/veya kişilere yönelik yakın fiziksel zararı veya zararı önlemek için bilgi paylaşımının gerekli olduğunu düşünmektedir.": "The Company reserves the right to transfer Client’s personal data to third parties if instructed by any regulatory authority, public agency, financial intelligence unit, police, governmental office, tax authority, law enforcement bodies, or if required by law, or in case the Company considers that sharing information is necessary to prevent imminent physical harm or damage to property and/ or persons.",
          "Verilerin Şirket ile aynı Şirketler grubundaki kuruluşlara aktarımı, yalnızca Müşteriye hizmetlerin sağlanması için sınırlı olacaktır.": "Transfer of data to entities within the same group of Companies as the Company shall be limited and only if necessary, for the provision of services to Client.",
          "Müşteri, bir iş ilişkisinin kurulmasına devam ederek, bu tür aktarımlara/aktarmalara açık ve net bir onay verir.": "By proceeding with the establishment of a business relationship, the Client gives express and unambiguous consent to such transfer/s.",
          "Çerezler": "Cookies",
          "Çerezler, Müşterinin Şirketin web sitesini/sitelerini ve/veya ticaret yazılımını kullanmasının bir sonucu olarak bilgisayarın sabit diskinde depolanan, genellikle benzersiz bir kimlik numarası veya değeri içeren küçük bilgi dosyalarıdır. Şirket, web sitesinin etkin çalışması ve bakımı için çerezleri kullanır.": "Cookies are small files of information, which often include a unique identification number or value, which are stored on computer’s hard drive as a result of the Client’s use of the Company’s website/s and/ or trading software. The Company uses cookies for effective operation and maintenance of its website.",
          "Şirket, hem oturum kimliği tanımlama bilgilerini hem de kalıcı tanımlama bilgilerini kullanabilir. Oturum kimliği tanımlama bilgisi, İstemci tarayıcıyı kapattığında sona erer. Kalıcı bir çerez, Müşterinin sabit diskinde uzun bir süre kalır. Kalıcı çerezler, Şirketin web sitesi ziyaretçilerinin konumunu ve ilgi alanlarını izlemesini ve hedeflemesini ve Şirketin web sitesini/sitelerini ziyaret ederek deneyimlerini geliştirmesini sağlar. Teknolojik destek için Şirketin web sitesine/sitelerine erişimi olabilecek üçüncü şahıslar kendi çerezlerini kullanabilirler.": "The Company might use both session ID cookies and persistent cookies. A session ID cookie expires when the Client close the browser. A persistent cookie remains on the Client’s hard drive for an extended period of time. Persistent cookies enable the Company to track and target the location and the interests of website’s visitors and to enhance their experience from visiting the Company’s website/s. Third parties that might have access to Company’s website/s for technological support, might use their own cookies.",
          "Güvenlik": "Security",
          "Şirket, Müşterinin sağladığı verilerin ve kişisel bilgilerin güvenliğinin, bütünlüğünün ve gizliliğinin kötüye kullanılmamasını sağlamak için uygun önlemleri sürdürmek için çaba göstermektedir. Bu tür sistemler ve prosedürler güvenlik ihlali riskini azaltırken, mutlak güvenlik sağlamazlar. Bu nedenle Şirket, hizmetlerin herhangi bir suiistimalden, arızadan, yasa dışı müdahalelerden veya burada saklanan bilgilere yetkisiz erişimden ve diğer bilgi güvenliği risklerinden veya Müşterinin Şirketin web sitesi/siteleri (dahil olmak üzere) üzerindeki veya bunlar aracılığıyla yaptığı özel iletişimlerden muaf olacağını garanti edemez. iletişim arayüzleri) özel kalacaktır.": "The Company makes efforts to maintain the appropriate safeguards in order to ensure that the security, integrity and privacy of the data and personal information that the Client have provided is not misused. While such systems and procedures reduce the risk of security breaches, they do not provide absolute security. Therefore, the Company cannot guarantee that the services will be immune from any wrongdoings, malfunctions, unlawful interceptions or unauthorized access to the information stored therein and to other information security risks, or that Client’s private communications on or through the Company’s website/s (including communication interfaces) will remain private.",
          "Linkler": "Links",
          "Müşteri, Şirketin web sitesini/sitelerini kullandığında, başka web sitelerine bağlantı verebilir. Bu Gizlilik ve Güvenlik Politikası, bu diğer siteler için geçerli değildir. Şirket, bu diğer siteler aracılığıyla üçüncü şahıslar tarafından toplanan hiçbir kişisel bilgiden sorumlu değildir.": "When the Client uses the Company’s website/s, he/she may be able to link to other websites. This Privacy & Security Policy does not apply to those other sites. The Company is not responsible for any personal information collected by third parties via those other sites.",
          "Yürütme ve Değişiklikler": "Enforcement and Amendments",
          "Müşteri, kayıt işlemine devam ederek, kişisel verilerin toplanması, saklanması ve kullanılması ve üçüncü taraflara iletilmesi dahil olmak üzere bu Politika'da belirtilen tüm hükümleri kabul ve muvafakat eder.": "The Client by proceeding with registration process agrees and consents to all the provisions stated in this Policy including collection, retention, and use of personal data and transmission to third parties.",
          "Şirket, bu Politikanın şartlarını zaman zaman değiştirebilir ve güncelleyebilir. En güncel versiyon Şirketin internet sitesinde yer alacaktır. Müşterinin, kişisel verilerle ilgili güncel bilgilerden haberdar olması için web sitesini sık sık ziyaret etmesi tavsiye edilir.": "The Company may change and update the terms of this Policy from time to time. The most recent version shall be available on the Company’s website. Client is advised to visit the website frequently in order to acquaint himself/herself with the updated information in regard to personal data.",
          "Müşterinin bu Gizlilik ve Güvenlik Politikası ve bunların uygulanmasıyla ilgili herhangi bir sorusu veya endişesi varsa, bunları şu adrese yönlendirebilir:support@btcruka.com": "If the Client has any questions or concerns regarding this Privacy and Security Policy and the enforcement thereof, he/she can refer them to: Support@btcruka.com",

          "İşlem Platformları": "Trading Platforms",
          "İşlevsellik, gelişmiş grafikler, hız ve performans RukaFx işlem platformlarında, forex piyasasının bütün avantajlarını yatırımcılarına 7 gün 24 saat hızlı platformu ile sunmaktadır.": "Functionality, advanced graphics, speed and performance RukaFx offers all the advantages of the forex market to its investors on its trading platforms, with its fast platform 24 hours a day, 7 days a week.",
          "Mobil Uygulamalarımızla Dilediğiniz Zaman İşlem Yapabilirsiniz!": "You can trade whenever you want with our mobile applications!",
          "Kazançlı Yatırımlara Hazır Olun": "Get Ready for Profitable Investments",
          "Profesyonel yatırımcıların tercihi Sirix'i Zenginleşmiş işlem fonksiyonlarının yanı sıra geliştirilmiş teknik ve temel analiz özellikleri ile Sirix, farklı piyasaları tek platformda sunma becerisyle önplana çıkıyor. Finansal piyaslardaki başarılı işlemler, üstün donanımlı ve çok fonksiyonlu işlem platformu ile mümkündür.": "Sirix, preferred by professional investors. With its enhanced technical and fundamental analysis features as well as enriched trading functions, Sirix stands out with its ability to present different markets on a single platform. Successful transactions in financial markets are possible with a highly equipped and multifunctional trading platform.",

        }
      },
      fa: {
        translation: {
          header: {
            login: "وارد شدن",
            createAccount: "ایجاد حساب کاربری",
            menu: "منو",
            investment: "شریک سرمایه گذاری BTCNG",
            webTrader: "ويب تريدر",
          },
          createAccount: {
            free: "ایجاد حساب کاربری رایگان",
            name: "نام و نام خانوادگی",
            email: "آدرس ایمیل",
            phone: "شماره تلفن",
            kvkk: "من موافقتنامه حفاظت و پردازش داده های شخصی و متن شفاف سازی را می پذیرم.",
            message: "پیغام",
            button: "ایجاد حساب کاربری",
            send: "ارسال"
          },
          routesButton: {
            campaign: "کمپین های فارکس",
            demo: "باز کردن حساب دمو"
          },
          "Evrak gerekmeden; hızlı , kolay ve güvenli bir şekilde işlem yap!": "بدون نیاز به مدارک؛ معامله سریع، آسان و ایمن!",
          "Yatırım dünyasında sınırlara takılmadan işlem yapabilme özğürlüğü RukaFX’te!": "آزادی تجارت بدون محدودیت در دنیای سرمایه گذاری در RukaFX است!",
          "BTCRuka Investment Ltd'ye aittir ve onun tarafından işletilmektedir.": "متعلق به BTCRuka Investment Ltd است و اداره می شود.",
          "Bir Hata Oluştu!": "مشکلی پیش آمد!",
          "Lütfen sayfayı yenileyip tekrar deneyin.": "لطفا صفحه را مجددا بارگزاری کرده و دوباره تلاش کنید.",
          "Mesajınız Alındı!": "پیام شما دریافت شد!",
          "Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.": "تیم ما در اسرع وقت با شما تماس خواهد گرفت.",
          "Üyelik İsteğiniz Alındı!": "درخواست عضویت شما دریافت شد!",
          "Üyeliğinizi Aktif Etmek İçin Lütfen E-mail Kutunuzu Kontrol Ediniz.": "لطفا صندوق پست الکترونیکی خود را علامت بزنید تا عضویت خود را فعال کنید.",
          "Güvenilir Yatırım Partneriniz": "شریک سرمایه گذاری مورد اعتماد شما.",
          "404 Sayfa Bulunamadı": "404 Page non trouvée",
          "Sayfa Bulunamadı !": "صفحه یافت نشد!",
          "Ulaşmaya çalıştığınız sayfa yayında değil veya link adresi değiştirilmiş olabilir. Anasayfaya dönmek için butona tıklayabilirsiniz.": "صفحه ای که می خواهید به آن دسترسی پیدا کنید فعال نیست یا آدرس پیوند ممکن است تغییر کرده باشد. برای بازگشت به صفحه اصلی می توانید روی دکمه کلیک کنید.",
          "ANASAYFAYA DÖN": "بازگشت به صفحه اصلی",
          "Tüm Hakları Saklıdır.": "تمامی حقوق محفوظ است.",
          "RukaFX Lisansını Görüntüle": "مشاهده مجوز RukaFX",
          "ASIC Sertifikası": "گواهینامه ASIC",
          // "Yatırım dünyasında sınırlara takılmadan işlem yapabilme özgürlüğü RUKAFX’te!":"La liberté de trader sans limites dans le monde de l'investissement est chez RUKAFX !",
          "X": "بستن",
          "Giriş Yap": "وارد شدن",
          "Hesap Oluştur": "ایجاد حساب",
          "HESAP OLUŞTUR": "ایجاد حساب",
          "WebTrader": "ويب تريدر",

          "Daha Fazla...": "بیشتر...",
          "Kurumsal": "سازمان",
          "Gerçek Hesap Oluşturun": "یک حساب واقعی ایجاد کنید",
          "Demo Hesap Oluşturun": "باز کردن حساب دمو",
          "Gerçek Hesap Aç": "یک حساب واقعی ایجاد کنید",
          "Demo Hesap Aç": "باز کردن حساب دمو",
          "5/24 Canlı Destek ve Küresel Piyasalarda Yatırım Avantajı": "5/24 پشتیبانی زنده و مزیت سرمایه گذاری در بازارهای جهانی",
          "En Hızlı Para Yatırma ve Çekme İşlemleri": "سریعترین سپرده ها و برداشتها",
          "Güvenli ve Avantajlı Kripto Para Yatırımı": "سرمایه گذاری ایمن و سودمند در رمزنگاری",
          "RukaFX ile Altın, Döviz, ve Hisse Senetleri Alın-Satın": "طلا ، ارز و سهام را با RukaFX خرید و فروش کنید",
          "Adres": "نشانی",

          "Sadece 3 Adımda Yatırım Yapmaya Başlayın!": "فقط در 3 مرحله سرمایه گذاری را شروع کنید!",
          "Kayıt Formunu Doldurun": "فرم ثبت نام را پر کنید",
          "Onay İşlemini Tamamlayın": "مراحل تأیید را تکمیل کنید",
          "Alış/Satış Yapmaya Başlayın": "شروع به خرید/فروش کنید",
          "HAZIRSAN BAŞLAYALIM!": "اگر آماده ایم شروع کنیم!",

          "RUKAFX ile bugün Başlayın!": "همین امروز با RUKAFX شروع کنید!",
          "RUKAFX, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. RUKAFX ile yatırım piyasalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.": "RUKAFX به مشتریان خود یک رویکرد خدمات قابل اعتماد و شفاف و همچنین اصول کم کمیسیون ارائه می دهد. با RUKAFX می توانید تجربه معاملاتی خود را در بازارهای سرمایه گذاری به حداکثر برسانید.",

          "DEMO HESAP AÇ": "باز کردن حساب آزمایشی",
          "FOREX KAMPANYALARI": "کمپین های فارکس",
          "RUKAFX Bilgi Rehberi": "راهنمای دانش RUKAFX",
          "Bilgi Rehberi": "راهنمای دانش",
          "Neden RUKAFX": "چرا RUKAFX",
          "Ücretsiz Eğitim": "آموزش رایگان",
          "Profesyonel eğitmenlerimizden telefonla ve ücretsiz olarak bire bir eğitim alarak yatırım hayatınıza bugün başlayabilirsiniz.": "شما می توانید زندگی سرمایه گذاری خود را از همین امروز با آموزش مربیان حرفه ای ما به صورت تلفنی و رایگان آغاز کنید.",
          "Hızlı Platform": "وبسایت سریع",
          "Modern ve teknolojik altyapımız ve sunucu desteğimiz ile hızlı ve güvenli işlem amaçlıyoruz.": "هدف ما معاملات سریع و امن با زیرساخت های مدرن با تکنولوژی بالا است.",
          "Lisanslı İşlem Platformu": "بستر تجاری مجاز  با لیسانس معتبر",
          "5/24 dünya ekonomisine yön veren para piyasalarında yatırım yapabilmeniz için lisanslı işlem platformumuz her an elinizin altında.": "بستر تجاری مجاز ما همیشه در اختیار شماست تا بتوانید در بازارهای پولی که اقتصاد جهانی  را شکل می دهند 5/24 سرمایه گذاری کنید.",
          "Sıfır Risk ile Yatırımı Öğrenin": "سرمایه گذاری با ریسک صفر را بیاموزید",
          "Yatırım işlemlerinize başlamadan önce ücretsiz olarak açacağınız Demo Hesap’ta 100.000 USD deneme bakiyesi ile işlem yapmayı risk almadan öğreneceksiniz.": "قبل از شروع معاملات سرمایه گذاری ، یاد می گیرید که با موجودی آزمایشی 100000 دلار در حساب آزمایشی تجارت کنید ، که این حساب را بدون هیچ گونه ریسکی باز می کنید",
          "Kaldıraçlı İşlem": "تجارت اهرمی",
          "Depozit olarak düşük miktarlarla yüksek pozisyonlar alınmasını sağlayarak yatırımdan en yüksek kârın elde edilmesini kolaylaştıran bir araçtır.": "این ابزاری است که با ارائه موقعیت های بالا با مبالغ کم به عنوان سپرده، بالاترین سود را از سرمایه گذاری کسب کنید.",
          "Çift Yönlü İşlem": "عملیات دو طرفه",
          "Yatırım araçlarının fiyatlarından hem düşerken hem de yükselirken para kazanabilirsiniz.": "شما می توانید از سهام  در حال کاهش و هم در حال افزایش درآمد کسب کنید.",

          "RUKAFX ile Işık Hızında Forex Ve Kripto Para İşlemleri": "با RUKAFX همه معاملات نقدی، فارکس، ارز دیجیتال خود را با سرعت بسیار بالا انجام دهید.",
          "İnternetten yatırım teknolojisinin nimetlerinden faydalanmak için en hızlı teknolojiyi tercih edin. En son teknoloji Ruka Yatırım Platformunu indirip yatırım işlemlerine başlayın!": "تفضيل التقنية الأسرع للاستفادة من مزايا تكنولوجيا الاستثمار عبر الإنترنت. قم بتنزيل أحدث تقنيات منصة روكا للاستثمار وابدأ التداول!",

          "Forex": "فارکس ",
          "Forex Nedir?": "فارکس چیست؟",
          "Forex veya kısa ismiyle FX, ingilizce ‘Foreign Exchange’ sözcüklerinin kısaltmasıdır. Forex piyasası da farklı ülke para birimlerinin, birbirlerine karşı görece değerlerinin, kaldıraçlı olarak alınıp satılmasına olanak sağlayan tezgahüstü bir döviz piyasasıdır.": "فارکس یا به اختصار FX مخفف کلمات انگلیسی 'Foreign Exchange' است. بازار فارکس یک بازار ارز بدون نسخه است که اجازه می دهد ارزش نسبی ارزهای کشورهای مختلف با اهرم خرید و فروش شود.",
          "Merkez bankalarından, uluslararası finans kuruluşlarına, serbest fonlara ve dünyanın her yerinden bireysel yatırımcılara kadar çok geniş bir yatırımcı kitlesine sahip olan Forex piyasası, bu yönüyle dünyanın en likit ve en hacimli (günlük ortalama 5 trilyon dolar) finansal piyasasıdır. Ayrıca Forex piyasaları 5 gün boyunca 24 saat açık olması nedeniyle de dünyanın farklı noktalarından gelebilecek veri akışına anında tepki verebilmektedir.": "بازار فارکس ، که دارای سرمایه گذاران گسترده ای از بانک های مرکزی ، موسسات مالی بین المللی ، صندوق های سرمایه گذاری و سرمایه گذاران انفرادی از سراسر جهان است ، نقدترین و حجیم ترین (به طور متوسط 5 تریلیون دلار در روز) بازار مالی در جهان است. به علاوه بر این ، از آنجا که بازارهای فارکس 24 ساعته به مدت 5 روز باز هستند ، می توانند در‌آن واحد به جریان داده ها از نقاط مختلف جهان واکنش نشان دهند.",

          "Spread Oranları": "اسپرد",
          "RukaFX rekabetçi spread oranlarımıza aşağıdaki tablodan ulaşabilirsiniz.": "در جدول زیر می توانید میزان اسپرد (Spread) رقابتی RukaFX را مشاهده کنید.",

          "RUKAFX ile bugün Başlayın!": "همین امروز با RUKAFX شروع کنید!",
          "RUKAFX, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. RUKAFX ile yatırım piyasalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.": "برای کسب درآمد همین الان میتوانید با ایجاد حساب کاربری رایگان از درآمد خوبی برخوردار شوید .",

          "Hakkımızda": "درباره ما",
          "Ulusararası kaldıraçlı piyasalarda uzman olan RukaFX, forex piyasasının bütün avantajlarını yatırımcılarına 5 gün 24 saat hızlı platformu ve profesyonel kadrosu ile sunmaktadır. Uzun süredir yatırım piyasalarında faaliyet gösteren RukaFX, yatırımcı odaklı bir kurum olmakla beraber güvenilir ve kurumsal hizmetler sunmaktadır.": "RukaFX که متخصص در بازارهای اهرمی بین المللی است، با بدسترسی سریع و کارکنان حرفه ای خود ، 5 روز در هفته و 24 ساعت در روز، تمام مزایای بازار فارکس را در خدمت سرمایه گذاران خود قرار می دهد. RukaFX ، که به مدت طولانی در بازارهای سرمایه گذاری فعالیت می کند ، یک موسسه متوجه سرمایه گذار است و خدمات قابل اعتماد و عمومی ارائه می دهد.",
          "Biz Kimiz": "با ما آشنا شوید.",
          "2017 yılından bu yana finansal piyasalarda aracı kurum görevini üstlenen Londra merkezli RukaFX, yüksek müşteri memnuniyetini ön planda tutarak yatırımcılarına hizmet sunmaktadır.": "RukaFX  مستقر در لندن که از سال 2017 به عنوان کارگزار (میانجی) در بازارهای مالی فعالیت می ",
          "RukaFX yüksek kaldıraç, düşük spread oranları ile bir forex yatırımcısının istediği bütün şartları tek bir platformda toplayan ve geniş ürün yelpazesinde işlemler sunan uluslararası bir forex firmasıdır. RukaFX'in alanında uzman kadrosu, yatırım piyasalarını 5 gün 24 saat analiz etmekte ve yatırımcılara isabetli analizler vermektedir. Profesyonel ekibimiz ile yatırım piyasalarında asla kendinizi yalnız hissetmeyeceksiniz. Birden çok yatırım yöntemini kullanarak kolay para yatırın, kolay para çekin ve yüksek kaldıraç düşük spreadlerle piyasalardan siz de payınızı alın.": "کند، با اولویت رضایت بالای مشتریان به سرمایه گذاران خود خدمات ارائه می دهد. RukaFX یک شرکت بین المللی وابسته به فارکس است که اهرم بالا ، نرخ اسپرد پایین و همه شرایط مورد نظر سرمایه گذار فارکس را در یک پلتفرم واحد جا داده و طیف وسیعی از معاملات را ارائه می دهد. کادر متخصص RukaFX بازارهای سرمایه گذاری را 24 ساعت در روز و 5 روز در هفته تجزیه و تحلیل می کنند و اطلاعات دقیقی را در اختیار سرمایه گذاران قرار می دهد. با تیم حرفه ای ما ، هرگز در بازارهای سرمایه گذاری احساس تنهایی نخواهید کرد. به راحتی سپرده گذاری کنید ، به راحتی و با استفاده از چندین روش سرمایه گذاری پول برداشت کنید و سهم خود را از بازارها با اهرم بالا و اسپرد کم بدست آورید.",
          "Neden RUKAFX.com?": "چرا RUKAFX.com ؟",
          "RUKAFX’i tercih etmeniz için onlarca sebepten bazıları;": "عمده‌ترین دلایلی که باید RukaFX را ترجیح دهید:",
          "Profesyonel Yatırım Hizmetleri": "خدمات سرمایه گذاری حرفه ای",
          "Rekabetçi Spread/Swap Oranları": "نرخ های گسترش/مبادله رقابتی",
          "Kriptoda 7/24, FX'de 5/24 Fiyat Akışı": "نمایش نرخ ارز دیجیتال همه روز و ساعت هفته و در FX همه ساعت های 5  روز هفته",
          "5/24 Canlı Destek": "/24 پشتیبانی زنده",
          "Kredi kartı, Coinler, Havale/Eft ve Daha Bir Çok Yöntem ile Para Yatırma/Çekme İmkanı": "فرصت واریز/برداشت با کارت اعتباری ، سکه ، انتقال پول و بسیاری روش های دیگر",
          "Swaplı,Swapsız Hesap Türleri": "انواع حساب مبادله‌ای و غیر مبادله‌ای",
          "Her Yatırıma Bonus Fırsatı": "فرصت پاداش برای هر سرمایه گذاری",
          "Lot İadesi": "بازپرداخت لات",
          "Ve dahası …": "و بیشتر …",
          "Ücretsiz Hesap Oluşturun": "ایجاد حساب کاربری رایگان",
          "İletişim": "ارتباط با ما",
          "Aklınıza takılan her türlü soru, görüş ve önerileriniz için bizimle iletişime geçebilirsiniz.": "در خصوص سوالات، پیشنهادات و انتقادات خود می‌توانید با ما تماس بگیرید.",
          "Telefon Numaranız": "شماره تلفن",
          "Whatsapp": "واتس‌آپ",
          "E-Posta Adresiniz": "نشانی ایمیل",
          "Mesaj": "پیام",
          "Adınız": "اسم",
          "Soyadınız": "اللقب الخاص بك",
          "Kişisel Verilerin Korunması, İşlenmesine Dair Sözleşme ve Aydınlatma Metni’ni kabul ediyorum.": "من موافقتنامه حفاظت و پردازش داده های شخصی و متن شفاف سازی را می پذیرم.",

          "RukaFX Güvenilir mi?": "مورد اعتماد است؟NGFOREX",
          "RukaFX yatırımcılarına ve yatırımcı adaylarına şeffaf, hızlı ve kapsamlı hizmet avantajı sunmaktadır. Sürekli gelişen yazılımsal altyapısı ile varlıklarınız çevrim içi ve çevrim dışı çok sayıda sistem ile korunmaktadır.": "ان جی  برای سرمایه گذاران مجموعه وعلاقه مندان به سرمایه گذاری امکانات شفاف و با سرعت بالا را در یک بستر امن که دائما در حال به روز رسانی از طرف تکنسین های حرفه ای است را ارائه میدهد.",
          "Forex’in Avantajları Nelerdir?": "آوانتاژهای فارکس چیست؟",
          "Blok zinciri, bir ağdaki işlemlerin daimi bir şekilde kaydedilmesini sağlar. Sistem veri tabanına benzer ancak geleneksel uçtan uca yerine merkezi olmayan bir hesap defteri kullanarak ağdaki her bir katılımcının kendilerine ait bir hesap defteri kopyasına sahip olmalarını ve tüm işlemleri görebilmelerini sağlar. Her bir blok, bir önceki blokla ilişkilendirilmiş ve şifrelenmiş bir referansla korunmaktadır, bu nedenle sisteme saldırıda bulunmak ya da hacklemek oldukça zordur.": "در شبکه بلاک چین امکان ثبت دائمی تراکنش ها در یک شبکه را فراهم می کند. این شبیه به یک پایگاه داده سیستم است، اما از یک دفتر کل غیرمتمرکز به جای استفاده از سرتاسر سنتی استفاده می کند، که به هر یک از شرکت کنندگان در شبکه اجازه می دهد تا نسخه خود را از دفتر کل داشته باشند و بتوانند همه تراکنش ها را ببینند. هر بلوک توسط یک مرجع رمزگذاری شده مرتبط با بلوک قبلی محافظت می شود، بنابراین حمله یا هک کردن سیستم بسیار دشوار است.",
          "Fοrеx’te Yatırım Yapabilecek Ürünler Nelerdir?": "در فارکس روی چه مواردی میتوانید سرمایه گذاری کنید؟",
          "Fοrеx işlemlerinde para birimlerinden emtialara ve endekslere kadar 700'e yakın üründe işlem yapılabilmektedir. Gerçek hesapta işlem yapmak isteyen yatırımcılar; Türk Lirası, Dolar ve EURO cinsinden bakiye yüklemesi yaparak, ulusal para birimlerinin (Döviz) yanı sıra Altın, Gümüş, Petrol, Çelik, Gaz gibi Emtialar ve Dünya Borsalarındaki hisse senetleri üzerinden yatırım yapmaya başlayabilirler.": "در معاملات فارکس، نزدیک به 700 محصول از ارز گرفته تا کالاها و شاخص ها قابل معامله است. سرمایه گذارانی که می خواهند با حساب واقعی معامله کنند. با بارگیری موجودی به دلار و یورو می‌توانند در کالاهایی مانند طلا، نقره، نفت، گندم، فولاد، گاز و سهام در بورس‌های جهانی و همچنین ارزهای ملی (ارز خارجی) سرمایه‌گذاری کنند.",
          "Gerçek Fοrеks Hesap Özellikleri Nedir?": "امکانات حساب واقعی فارکس چیست؟",
          "Gerçek hesap ile %100 oranda aynı olan demo hesapta işlemler yapıp belli bir tecrübe ve bilgiye ulaştıktan sonra gerçek hesaba geçtiğinizde pek çok ayrıcalıktan yararlanabileceksiniz. Demo Fοrеx Hesabı ile belli bir tecrübeye ulaştıktan sonra Gerçek Hesap’a geçtiğinizde hoşgeldin bonusu haricinde her yatırımınıza çeşitli VIP  promosyonlardan faydalanacaksınız. Fοrеx yatırımınızdan elde ettiğiniz kazancı işlemin tamamlanması sonrasında anında banka hesabınıza aktarabilmek RukaFX ile mümkün.": "پس از انجام تراکنش های اکانت دمو که 100% مشابه همان اکانت واقعی است و پس از کسب تجربه و دانش خاص، می توانید از امتیازات بسیاری بهره مند شوید. پس از اینکه تجربه خاصی با حساب دمو فارکس به دست آوردید، برای هر سرمایه گذاری به جز پاداش خوش آمدگویی، وقتی به حساب واقعی تغییر می کنید، از تبلیغات و پشتیبانی VIP مختلف بهره مند خواهید شد. با NGFX، این امکان وجود دارد که بلافاصله پس از اتمام تراکنش، درآمد حاصل از سرمایه گذاری فارکس خود را به حساب بانکی و کیف پول خود منتقل کنید.",
          "Fοrеx Piyasalarında İşlem Saatleri Nedir?": "ساعات کاری بازار فارکس چگونه است؟",
          "Fοrеx piyasası uluslararası bir piyasa olduğundan haftanın 5 günü (Pazartesi,Salı,Çarşamba,Perşembe,Cuma) 24 saat işlem yapabilme imkanınız mevcuttur. Fοrеx Piyasası Pazar günü Tsi 00.00'da açılır ve Cuma günü 23.59'a kadar işlem yapmaya devam edebilirsiniz. ABD piyasasının açılış saati Türkiye saati ile 16.30’dur. Amerika Birleşik Devletleri piyasası Türkiye saati ile gece 12.00’ye kadar işlem görür. Ardından yine Yeni Zelanda piyasası açılır.Cuma gecesi ABD piyasasının kapanmasıyla Fοrеx piyasaları tatile girer.": "از آنجایی که بازار فارکس یک بازار بین المللی است، شما این فرصت را دارید که 24 ساعت شبانه روز و 5 روز هفته (دوشنبه، سه شنبه، چهارشنبه، پنجشنبه، جمعه) معامله کنید. بازار فارکس در ساعت 00:00 یکشنبه باز می شود و می توانید تا ساعت 23:59 روز جمعه به معامله ادامه دهید. ساعت افتتاح بازار آمریکا 18:00 به وقت ایران است. بازار ایالات متحده تا ساعت 01:30 به وقت ایران معامله می شود. سپس بازار نیوزلند دوباره باز می شود.با بسته شدن بازار ایالات متحده در جمعه شب، بازارهای فارکس در تعطیلات هستند.",
          "Spread Oranı Nedir?": "میزان اسپرد چیست؟",
          "Spread bir döviz kurunun alış ve satış fiyatları arasındaki farktır. Satış fiyatı, bir para biriminin satıldığı andaki oranını, alış fiyatı ise alınmak istendiği andaki fiyatını ifade eder. RukaFX sizlere onlarca piyasa enstrümanıyla en dar ve sabit spread avantajlarıyla işlem olanağı sunuyor. Spread oranlarımızı görmek ve detaylı bilgi almak için lütfen müşteri temsilcilerimiz ile iletişime geçin.": "اسپرد تفاوت قیمت بین خرید و فروش یک نرخ ارز است. قیمت فروش به نرخ ارز در زمان فروخته شدن آن اشاره دارد و قیمت خرید به قیمت زمانی که می‌خواهید خریداری شود اشاره دارد. NGFX به شما این فرصت را می دهد که با ده ها ابزار بازار با محدودترین و ثابت ترین مزایا معامله کنید شناور نبودن اسپرد ها از امتیاز های مثبت ما هست. لطفا با نمایندگان مشتریان ما تماس بگیرید تا نرخ گسترش ما را ببینید و اطلاعات دقیق را دریافت کنید.",

          "Döviz Çiftleri": "دوویز",
          "Döviz, Türkçe’de yabancı ülkelerin paralarına verilen genel isimdir. Parite ise bir ülkenin para biriminin başka bir ülkenin para birimine karşı değeridir. Paritede ilk yazılan para birimi baz (ana) para birimini ifade eder. İkinci yazılan para birimi ise karşıt para birimini ifade etmektedir.": "دوویز نام عمومی است که به ارزهای کشورهای خارجی به زبان ی داده می شود. برابری ارزش پول یک کشور در برابر واحد پول کشور دیگر است. اولین ارز در برابری نشان دهنده پول پایه (اصلی) است. واحد دوم ارز مقابل است.",
          "RUKAFX ile döviz çiftleri arasında alım-satım işlemleri yaparak kazanç elde edebilirsiniz. Uzman analist ekibimiz tüm yatırım fırsatlarında yanınızda olacaktır.": "ارز نام عمومی است که به ارزهای کشورهای خارجی به زبان ی داده می شود. برابری ارزش پول یک کشور در برابر واحد پول کشور دیگر است. اولین ارز در برابری نشان دهنده پول پایه (اصلی) است. واحد دوم ارز مقابل است.",

          "CFD İşlemleri": "قرارداد‌های  (سی.اف.دی)",
          "Dünyanın önde gelen birçok şirketine yatırım ayrıcalığı artık RUKAFX’te!": "امتیاز سرمایه گذاری در بسیاری از شرکت های پیشرو جهان در حال حاضر در RUKAFX است!",
          "Hisse senedi; bir şirketin sermayesinin eş değer parçalarından birini ifade eder. Bir şirkete ait hisse senedi satın alarak, o şirketin ortakları arasına katılmış olursunuz. Hisse senedi yatırım yaparken satın alacağınız şirketin faaliyetleri, borçluluk durumu, büyüme beklentileri, kar durumu, temettü (kar payı) miktarı kısaca şirketle ilgili her şeyi iyice araştırmalısınız. Başarılı, uzun yıllar faaliyet gösteren ve kar elde eden şirketleri seçerek hisse senedi yatırımdan kazanç sağlayabilirsiniz.": "سهام؛ به یکی از قسمتهای معادل سرمایه شرکت اشاره دارد. با خرید سهام یک شرکت، یکی از شرکای آن شرکت می شوید. هنگام سرمایه گذاری در سهام ، باید به طور خلاصه همه چیز را در مورد فعالیتهای شرکت، بدهی، انتظارات از رشد، وضعیت سود، میزان سود (سهم سود) تحقیق کنید. شما می توانید با انتخاب شرکت های موفق، سالهای طولانی فعالیت و کسب سود، از سرمایه گذاری در سهام سود ببرید",
          "Hisse yatırımlarınızı artık yurt içi ile sınırlamak zorunda değilsiniz. RUKAFX ile çeşitli sektörlerde dünyanın önde gelen şirketlerinde haftanın 5 günü boyunca alış - satış işlemi gerçekleştirebilirsiniz. (Apple, Amazon, Facebook, Netflix, Tesla vb.) Bu sayede dünya piyasalarına kolayca ulaşabilir ve uluslararası şirketlerin hisse senetlerini kısa, orta ya da uzun vadede alıp satabilirsiniz.": "دیگر لازم نیست سرمایه گذاری سهام خود را به سرمایه گذاری های داخلی محدود کنید. با RUKAFX، می توانید 5 روز در هفته معاملات خرید و فروش را در شرکت های برجسته جهان در بخش های مختلف انجام دهید. (اپل، آمازون، فیس بوک، نتفلیکس، تسلا و غیره) به این ترتیب، شما به راحتی می توانید به بازارهای جهانی دسترسی داشته باشید و سهام شرکت های بین المللی را در کوتاه مدت، میان مدت یا بلند مدت خرید و فروش کنید.",

          "Emtialar": "کامودیتی",
          "Ticarete konu olan mallara genel olarak emtia denilmektedir. Emtialar dünya ticaretinde alınıp satılan, ekonomilere ve güncel yaşama yön veren en önemli ticaret araçlarıdır. Emtia ürünlerinin fiyatları arz ve talebe göre oluşmaktadır. Bu yüzden alım satıma konu olan malın kullanım alanı, arz olanakları, güncel talebi ya da kullanıldığı alanlar ön plana çıkabilmektedir.": "به کالاهایی که مشمول تجارت هستند کامودیتی می گویند. کالاها مهمترین ابزار تجاری هستند که در تجارت جهانی خرید و فروش می شوند و اقتصاد و زندگی فعلی را شکل می دهند. قیمت محصولات با توجه به عرضه و تقاضا تعیین می شود. به همین دلیل محدوده استفاده، امکانات عرضه، تقاضای فعلی یا زمینه های استفاده از کالاهای موضوع خرید و فروش می تواند مطرح شود.",
          "Emtia ürünlerinin üretim koşulları, talep düzeyi ve ham madde olanakları fiyat oluşumunda dikkate alınan faktörler arasındadır. Bu durum birçok yatırımcının dikkatini çekmiş ve emtialar yatırımların yoğunlaştığı enstrümanlardan biri haline gelmiştir. Platformumuzda ulaşabileceğiniz emtialar piyasa koşullarında en çok talep gören ürünlerdir.": "شرایط تولید محصولات کالایی ، سطح تقاضا و امکانات مواد اولیه از جمله عواملی است که در شکل گیری قیمت در نظر گرفته شده است. این وضعیت توجه بسیاری از سرمایه گذاران را به خود جلب کرده است و کالاها به یکی از ابزارهایی تبدیل شده اند که سرمایه گذاری در آن متمرکز است. کالاهایی که می توانید در وبسایت ما به آنها دسترسی پیدا کنید ، پرمصرف ترین محصولات در شرایط بازار هستند.",
          "RUKAFX platformu üzerinden altın, gümüş, petrol, bakır ve doğalgaz ürünlerinin tamamında rekabetçi fiyatlarla hizmet sağlamaktayız.": "ما خدمات را با قیمت رقابتی برای همه محصولات طلا، نقره، نفت، مس و گاز طبیعی از طریق پلت فرم RUKAFX ارائه می دهیم.",

          "Duyurular": "اعلامیه ها",
          "RUKAFX ile ilgili tüm güncel duyuruları bu kısımdan takip edebilirsiniz.": "می توانید تمامی اطلاعیه های به‌‌روز RUKAFX  را از این قسمت دنبال کنید.",
          "RukaFX Türkçe Dil Desteği Vermeye Başladı!": "شروع به ارائه پشتیبانی از زبان فارسی کرد!RukaFX ",
          "5 ayrı ülkede yatırımcılara hizmet veren RukaFX; Türkçe dil desteği hizmetine başladı! Türk yatırımcılara özel olarak hazırlanan RukaFX Türkçe dil destekli web sitesi; uzman danışmanlar ve son teknoloji alt yapısı ile ayrıcalıklı yatırım yapmak isteyenleri bekliyor.": "  RukaFX در ۵ کشور مختلف به سرمایه‌گذاران خدمات ارائه می‌دهد. هم‌اکنون این شرکت شروع به ارائه خدمات در زبان فارسی کرده است. وب سایت RukaFX  که از زبان فارسی پشتیبانی می‌کند، مخصوص سرمایه گذاران  است که می خواهند با کمک مشاوران متخصص و زیرساخت های پیشرفته‌مان سرمایه گذاری ممتاز و متفاوتی انجام دهند.",

          "RukaFX Farsça Dil Desteği Vermeye Başladı!": "RukaFX شروع به ارائه پشتیبانی از زبان فارسی کرد!",
          "5 ayrı ülkede yatırımcılara hizmet veren RukaFX; Farsça dil desteği hizmetine başladı! Türk yatırımcılara özel olarak hazırlanan RukaFX Farsça dil destekli web sitesi; uzman danışmanlar ve son teknoloji alt yapısı ile ayrıcalıklı yatırım yapmak isteyenleri bekliyor.": "RukaFX که به سرمایه گذاران در 5 کشور مختلف خدمات می دهد. خدمات پشتیبانی زبان فارسی آغاز شد! وب سایت فارسی زبان پشتیبانی RukaFX ویژه سرمایه گذاران ترک تهیه شده است. با مشاوران متخصص و زیرساخت های روز دنیا منتظر کسانی است که می خواهند سرمایه گذاری ممتازی انجام دهند.",

          "Promosyonlar": "کمپین‌ها",
          "Kampanya Kuralları": "قوانین کمپین",
          "%20 Hoşgeldin Bonusu": "20  درصد پاداش خوش‌آمدید",
          "RUKAFX’te gerçek hesap açtıktan sonra ilk yatırımınızda geçerlidir.": "این کمپین برای اولین سرمایه گذاری شما، پس از افتتاح حساب واقعی در RUKAFX قابل استفاده است.",
          "Kazanılan bonus miktarı yapılacak forex işlemlerinde marjin seviyesini yükseltmek için takviye görevi görür, çekilemez. Fakat bonus kullanılarak elde edilen kâr çekilebilir. Bonus alındıktan sonra stop out olunması durumunda, yatırımcının stop out olduktan sonra hesabında kalan meblağ almış olduğu bonus miktarı veya daha azı kadar ise, hesabında kalan meblağ almış olduğu bonusa eşdeğer sayılacağı için bu miktar için çekim talebinde bulunulamaz. Örneğin; 1.000 $ yatırıp %30 Yatırım Bonusu ile 300$ hoşgeldin bonusu almış olan bir yatırımcı stop out olması durumunda, hesabında 300 $ veya daha düşük bir miktar kaldığında, bu meblağ yatırımcının hesabından düşülür.": "مبلغ پاداش به دست آمده به عنوان افزاینده سطح سود در معاملات فارکس عمل می کند و قابل برداشت نیست. اما سود حاصل از پاداش قابل برداشت است. در صورت توقف (استاپ‌آوت) پس از دریافت پاداش، اگر مبلغ باقی مانده در حساب سرمایه گذار پس از توقف معادل یا کمتر از مبلغ دریافتی پاداش باشد، مبلغ باقی مانده در حساب معادل پاداش دریافتی محسوب می شود ونمی توان درخواست برداشت داد. به عنوان مثال؛ در صورتی که معامله گر 1000 دلار سپرده گذاری کرده و 30 درصد پاداش سپرده گذاری و 300 دلار پاداش خوش آمدید دریافت کرده و استاپآوت کرده باشد این مبلغ زمانی از حساب معامله گر کسر می شود که 300 دلار یا کمتر از حساب آنها باقی مانده باشد.",
          "Promosyona katılım için web sitemizden RUKAFX Canlı Destek Hattı veya Whatsapp üzerinden müşteri temsilcileri ile iletişime geçilmesi yeterlidir.": "برای شرکت در این کمپین، کافی است از طریق خط پشتیبانی زنده در وب سایت RUKAFX یا از طریق Whatsapp تماس بگیرید.",
          "RUKAFX, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.": "RUKAFX  این حق را برای خود محفوظ می دارد که قوانین این تبلیغ را تغییر داده یا تبلیغ را به طور کلی لغو کند. این وظیفه خود سرمایه گذار است که از قوانین جاری هر گونه تبلیغی پیروی کند.",
          "Maksimum alınabilir bonus miktarı 5000$’dır.": "حداکثر مبلغ پاداش دریافتی 5000 دلار است.",
          "Bu kampanya, başka kampanya ile birleştirilemez. RUKAFX kampanya koşullarında değşiklik yapma hakkını saklı tutar.": "این تبلیغ نمی تواند با تبلیغات دیگر یب شود.RUKAFX  	این حق را برای خود محفوظ می دارد که در شرایط کمپین تغییراتی ایجاد کند.",
          "%10 Referans Bonusu": "10٪  	پاداش معرفی اعضای جدید",
          "Gerçek hesap sahibi olan yatırımcılarımız şirketimize yeni yatırımcılar için referans olması durumunda ilk yatırımları üzerinden %10 bonus kampanyasına hak kazanabilir.": "سرمایه گذاران ما که دارای حساب های واقعی هستند، می توانند در اولین سرمایه گذاری خود برای معرفی عضو جدید در اولین سرمایه‌گذاری خود 10% پاداش دریافت کنند. ",
          "Tüm yatırımcılarımızın yeni referanslarının sadece ilk yatırımlarında geçerli olan bonus kampanyasıdır.": "این کمپین فقط برای اولین سرمایه گذاری سرمایه گذاران ما قابل استفاده است.",
          "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.": "مبلغ اضافه شده به حساب با پاداش ، مبلغ قابل برداشت نیست.",
          "Referans bonusu, başka herhangi bir kampanya ile birleştirilemez.": "پاداش ارجاع را نمی توان با تبلیغات دیگر یب کرد.",
          "Yatırımcılarımız elde ettikleri karlarını çekerken bonus tutarları silinmez.": "وقتی سرمایه گذاران ما سود خود را برداشت می کنند ، مبلغ پاداش حذف نمی شود.",
          "Bonus hak edişi elde edilirken yatırılmış olan fon miktarı çekilmek istenirse öncesinde bonus tutarının silinmesi gerekmektedir.": "اگر بخواهید مبلغ واریز شده هنگام کسب پاداش را برداشت کنید، مبلغ پاداش باید از قبل حذف شود.",
          "RUKAFX bu kampanyayı değiştirme veya tamamen iptal etme hakkına sahiptir. Kampanyada yapılacak herhangi bir değişikliği takip etme sorumluluğu yatırımcılarımızın kendilerine aittir.": "RUKAFX 	 حق تغییر کمپین یا لغو کامل آن را دارد. این مسئولیت سرمایه گذاران ما است که بر تغییرات ایجاد شده در کمپین نظارت کنند.",
          "Bu bonustan hak edilebilecek max. bonus tutarı 1000$’dır.": "حداکثر مبلغ پاداش 1000 دلار است.",
          "Kayıp Sigorta Kampanyası": "کمپین بیمه گمشده",
          "Kampanyadan faydalanmak için alt deposit tutarı min. 300$’dır.": "برای بهره مندی از کمپین ، حداقل مبلغ سپرده حداقل 300 دلار است.",
          "Bu kampanya sadece yeni müşteriler ile hesabı stop out olan yatırımcılar için geçerlidir.": "این پیشنهاد فقط برای مشتریان جدید ومعامله گران که حساب آنها استاپ‌آوت است معتبر است.",
          "Yatırımcının deposit sonrası işleme başladığı gün itibari ile 3. Günün sonunda kaybı hesaplanır, bir sonraki gün kaybının yarısı iade edilir.": "از روزی که سرمایه گذار پس از سپرده گذاری شروع به معامله می کند، زیان در پایان روز سوم محاسبه می شود و نیمی از زیان روز بعد بازگردانده می شود.",
          "Yatırımcı bu kampanyadan yararlanabilmek için major paritelerde işlem yapmalıdır.": "به منظور بهره مندی از این کمپین ، سرمایه گذار باید با برابری های عمده تجارت کند.",
          "Bu kampanyadan kazanılabilecek maksimum bonus tutarı 1000$'dır.": "حداکثر مبلغ پاداش قابل دریافت از این کمپین 1000 دلار است.",
          "Bu kampanya, başka kampanya ile birleştirilemez. RUKAFX kampanya koşullarında değşiklik yapma hakkını saklı tutar.": "این تبلیغ نمی تواند با تبلیغات دیگر یب شود. RUKAFX این حق را برای خود محفوظ می دارد که در شرایط کمپین تغییراتی ایجاد کند.",

          "Hesabını RUKAFX’e Taşıyan Herkese %30'a Kadar Bonus Fırsatı": "تا 30٪ فرصت پاداش برای هرکسی که حساب خود را به RUKAFX منتقل کند",
          "Hesap Taşıma Bonusu, farklı bir aracı kurumdan RUKAFX’e taşınacak olan aktif forex hesabındaki varlık (equity) miktarının %30’i kadar olup, her yatırımcı bu promosyondan yalnızca 1 kez faydalanabilir.": "پاداش انتقال حساب 30 درصد از دارایی (حقوق صاحبان سهام) در حساب فارکس فعال است که از یک کارگزاری مختلف به RUKAFX  منتقل می شود و هر سرمایه گذار فقط یک بار می تواند از این کمپین استفاده کند.",
          "%30 Hesap Taşıma Bonusu’ndan kazanılabilecek maksimum bonus miktarı 3000$’dır.": "حداکثر مبلغ جایزه ای که می توانید از 30٪ جایزه انتقال حساب دریافت کنید 3000 دلار است.",
          "RUKAFX, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.": "RUKAFX این حق را برای خود محفوظ می دارد که قوانین این تبلیغ را تغییر داده یا تبلیغ را به طور کلی لغو کند. این وظیفه خود سرمایه گذار است که از قوانین جاری هر گونه تبلیغی پیروی کند.",
          "Ana paranızın %80 'ini çektiğiniz taktirde ana paranızla almış olduğunuz bonus hesabınızdan düşülücektir.": "در صورت برداشتن 80 درصد از اصل خود، پاداشی که با اصل خود دریافت کرده اید از حساب شما کسر می شود.",
          "Kullanıcının kural dışı faaliyetlerde bulunduğu ya da hileye başvurduğu tespit edilir ise hiçbir mazeret belirtilmeden RUKAFX hesabı Xılacaktır.": "اگر مشخص شود که کاربر فعالیت های غیرقانونی انجام داده یا به تقلب روی آورده است، حساب RUKAFX بدون هیچ بهانه ای بسته می شود.",
          "Kullanıcının kural dışı faaliyetlerde bulunduğu ya da hileye başvurduğu tespit edilir ise hiçbir mazeret belirtilmeden RUKAFX hesabı kapatılacaktır.": "در صورتی که مشخص شود کاربر اقدام به انجام فعالیت های غیرقانونی یا تقلب کرده است، حساب RUKAFX بدون هیچ بهانه ای بسته می شود.",
          "%30 Kripto Para Yatırımına Özel Hoşgeldin Bonusu": "پاداش خوش آمدید %30 برای سرمایه گذاری در ارزهای دیجیتال",
          "Bu promosyondan ilk kez yatırımda bulunacak olan üyeler faydalanabilir.": "اعضایی که برای اولین بار سرمایه گذاری می کنند می توانند از این کمپین بهره مند شوند.",
          "Bu promosyondan faydalanabilmesi için yatırımcının hesabına fonlamayı kabul edilen kripto paralar ile gerçekleştirmesi gerekmektedir.": "به منظور بهره مندی از این کمپین، سرمایه گذار باید حساب خود را با ارزهای دیجیتال مد‌نظر تأمین کند.",
          "Kazanılan bonus miktarı marjin seviyenizi yükseltmek için destek niteliği taşır, çekilemez. Fakat bonus kullanılarak sağlanan kazanç çekilebilmektedir.": "میزان پاداش به دست آمده حمایتی برای افزایش سطح سود شما است و قابل برداشت نیست. با این حال، سودهای به دست آمده از پاداش قابل برداشت است.",
          "Promosyona katılmak için canlı destek hattı veya tarafınıza özel atanan müşteri temsilciniz ile iletişime geçmeniz yeterlidir.": "برای شرکت در کمپین، کافی است با خط پشتیبانی مستقیم یا نماینده مشتری خود که مخصوص شما تعیین شده است تماس بگیرید.",
          "Yatırımcı çekim talebi verdiğinde bonus hesabından silinmektedir.": "هنگامی که سرمایه گذار درخواست برداشت می کند ، پاداش از حساب او حذف می شود.",
          "RUKAFX bu promosyonu değiştirme veya iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.": "RUKAFX  حق تغییر یا لغو این تبلیغ را برای خود محفوظ می دارد. این وظیفه خود سرمایه گذار است که از قوانین جاری هر گونه تبلیغی پیروی کند.",
          "%20 Stop Out Bonusu": "پاداش 20%  استاپ آوت",
          "%10 Yatırım Bonusu": "پاداش 10% سرمایه گذاری",
          "RUKAFX’te yapacağınız her yatırıma %10 bonus kazanın!": "برای هر سرمایه گذاری که در RUKAFX انجام می دهید 10 درصد پاداش دریافت کنید!",
          "Her yatırımcı her para yatırma işlemi için bu kampanyadan yararlanabilir.": "هر سرمایه گذار می تواند با هر سپرده ای از این کمپین سود ببرد.",
          "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.": "مبلغ اضافه شده به حساب با پاداش ، مبلغ قابل برداشت نیست.",
          "Bonus kampanyasından yararlanan yatırımcılarımız başka herhangi bir kampanyadan yararlanamazlar.": "سرمایه گذاران ما که از کمپین پاداش سود می برند نمی توانند از هیچ کمپین دیگری سود ببرند.",
          "Yatırımcılarımızın yapmış oldukları işlemlerde hesabın güçlendirilmesine ve daha yüksek oranlar ile yatırım yapmasına imkan sağlar.": "این کمپین به سرمایه گذاران ما این امکان را می دهد که در معاملاتی که انجام داده اند حساب را تقویت کرده و با نرخ بالاتری سرمایه گذاری کنند.",
          "Bonus hak edişi elde edilirken yatırılmış olan fon miktarı çekilmek istenirse öncesinde bonus tutarının silinmesi gerekmektedir.": "اگر می خواهید مبلغ واریز شده هنگام کسب پاداش برداشت شود، مبلغ پاداش باید از قبل حذف شود.",
          "Yatırımcılarımız en fazla 15.000$ alabilmektedir.": "سرمایه گذاران ما می توانند حداکثر 15000 دلار دریافت کنند.",

          "Gizlilik ve Güvenlik Politikası": "سياسة الخصوصية والأمان",
          "BTCRUKA ('Şirket'), geçerli yasal gerekliliklere uygun olarak Müşteriden elde edilen kişisel verilerin mahremiyetini ve gizliliğini korumayı taahhüt eder. Şirket, yalnızca Müşteri ile iş ilişkisi için gerekli olduğu ölçüde veri toplayacaktır.": "تلتزم BTCRUKA ('الشركة') بحماية خصوصية وسرية البيانات الشخصية التي يتم الحصول عليها من العميل ، بما يتوافق مع المتطلبات القانونية المعمول بها. يجب على الشركة جمع البيانات فقط بالقدر الذي يكون ضروريًا لعلاقة العمل مع العميل.",
          "Bu Gizlilik ve Güvenlik Politikası, Müşterinin web sitelerimizi ziyaretleri sırasında toplanan veriler de dahil olmak üzere, Şirketin Müşteri hakkında kişisel verileri nasıl topladığını, muhafaza ettiğini, kullandığını ve ilettiğini açıklar.": "توضح سياسة الخصوصية والأمان هذه كيفية قيام الشركة بجمع البيانات الشخصية الخاصة بالعميل والحفاظ عليها واستخدامها ونقلها ، بما في ذلك البيانات التي تم جمعها أثناء زيارات العميل لمواقعنا الإلكترونية.",
          "Müşterinin Kişisel Verilerinin Toplanması ve Kullanımı": "جمع واستخدام البيانات الشخصية للعميل",
          "Müşterinin, iş ilişkisinin kurulması, hizmetlerin sağlanması ve alım satım hesaplarına ve faaliyetlerine erişim için gerekli kişisel verileri Şirkete sağlaması gerekmektedir. Kayıt işlemi sırasında sağlanan veriler, diğerlerinin yanı sıra; Müşterinin doğum tarihi, tam adı ve soyadı, tam ikamet ve/veya iş adresi, müşterinin kimlik belgelerinde mevcut bilgiler (yani Pasaport / Kimlik Kartı), iletişim bilgileri (yani cep telefonu numarası, e-posta adresi). Bu bilgiler, Şirketin Müşteriyi tanımlamasına ve doğrulamasına, Müşteriye bir ticaret hesabı ve erişim kodu/şifresi etkinleştirmesine ve sağlamasına ve Müşteri ile iletişime geçmesine ve mevcut hizmetler, ürünler ve promosyonlar hakkında bilgi sağlamasına olanak tanır.": "يتعين على العميل تزويد الشركة بالبيانات الشخصية اللازمة لإقامة علاقة العمل ، وتوفير الخدمات والوصول إلى حساب / حسابات التداول والنشاط. قد تشمل البيانات المقدمة أثناء عملية التسجيل ، من بين أمور أخرى ؛ تاريخ ميلاد العميل ، والاسم الكامل واللقب ، والعنوان السكني و / أو التجاري الكامل ، والمعلومات المتوفرة في وثائق هوية العميل (مثل جواز السفر / بطاقة الهوية) ، ومعلومات الاتصال (أي رقم الهاتف المحمول ، وعنوان البريد الإلكتروني). تمكن هذه المعلومات الشركة من تحديد العميل والتحقق منه ، وتنشيط وتزويد العميل بحساب تداول ورمز وصول / كلمة مرور ، والاتصال بالعميل وتقديم معلومات عن الخدمات والمنتجات والعروض الترويجية المتاحة.",
          "Şirket ayrıca, Müşterinin web sitelerini, özel arayüzleri veya iletişim kanallarını, ticaret yazılımlarını veya sağlanan platformları kullanımından da bilgi alır. Bu bilgiler, ziyaret edilen site alanları, görüntülenen sayfalar, ziyaretlerin sıklığı ve süresi, İnternet Protokolü (IP) adresi, bilgisayar tanımlama parametreleri, yürütülen işlem türleri, indirilen belgeler, fare tıklamaları, fare hareketleri ve kaydırma etkinliğini içerebilir.": "تحصل الشركة أيضًا على معلومات من استخدام العميل لمواقعها الإلكترونية أو واجهات مخصصة أو قنوات اتصال أو برامج أو منصات تداول مقدمة. قد تتضمن هذه المعلومات مناطق الموقع التي تمت زيارتها ، والصفحات التي تم عرضها ، وتكرار الزيارات ومدتها ، وعنوان بروتوكول الإنترنت (IP) ، ومعلمات تعريف الكمبيوتر ، وأنواع المعاملات التي يتم إجراؤها ، والمستندات التي يتم تنزيلها ، ونقرات الماوس ، وحركات الماوس ، ونشاط التمرير",
          "Şirket, müşterilerinin ihtiyaçlarını karşıladığından emin olmak için anonim istatistiksel bilgiler toplar. Şirket, bu tür verileri toplayarak mevcut ve potansiyel müşterilere sunulan hizmetleri izleyebilir ve iyileştirebilir. Şirket, bu tür bilgileri üçüncü kişilerle paylaşabilir.": "تقوم الشركة بجمع معلومات إحصائية مجهولة المصدر للتأكد من أنها تلبي احتياجات عملائها. من خلال جمع هذه البيانات ، تكون الشركة قادرة على مراقبة وتحسين الخدمات المقدمة للعملاء الحاليين والمحتملين. يجوز للشركة مشاركة هذه المعلومات مع أطراف ثالثة.",
          "Müşteri Bilgilerini Güncelleme": "تحديث معلومات العميل",
          "Müşterinin Şirket ile olan iş ilişkisi sırasında, Müşteriden, yenilenen kimlik belgeleri, güncellenmiş iletişim bilgileri dahil olmak üzere, bu ilişkinin sürdürülmesi için gerekli olan kişisel verileri sağlaması istenebilir.": "أثناء علاقة عمل العميل مع الشركة ، قد يُطلب من العميل تقديم البيانات الشخصية اللازمة للحفاظ على هذه العلاقة ، بما في ذلك أي وثيقة تعريف مجددة ومعلومات اتصال محدثة.",
          "Ayrıca Müşteri, kişisel bilgilerdeki herhangi bir değişikliği Şirkete bildirebilir. Şirket, Müşterinin kişisel bilgilerini Müşterinin talimatlarına uygun olarak değiştirecek ve Şirketin ilgili kişisel verileri yasal veya yasal amaçlarla tutması ve/veya Müşteriye talep edilen hizmetleri sunması gerektiği durumlar dışında, önceden elde edilen bilgileri silecektir. veya iş kayıtlarını tutmak için.": "بالإضافة إلى ذلك ، يجوز للعميل إبلاغ الشركة بأي تغيير في التفاصيل الشخصية. يجب على الشركة تغيير المعلومات الشخصية للعميل وفقًا لتعليمات العميل وحذف المعلومات التي تم الحصول عليها مسبقًا باستثناء الحد الذي يُطلب من الشركة الاحتفاظ بالبيانات الشخصية ذات الصلة لأغراض تنظيمية أو قانونية و / أو تزويد العميل بالخدمات المطلوبة أو للاحتفاظ بسجلات العمل.",
          "Verilerin Üçüncü Kişilere Aktarılması": "نقل البيانات إلى أطراف ثالثة",
          "Kişisel veriler, Şirket tarafından Avrupa Ekonomik Alanında ve Avrupa Ekonomik Alanı dışında bulunan üçüncü şahıslara yalnızca bu Politikada açıklanan amaçlar doğrultusunda aktarılabilir.": "قد يتم نقل البيانات الشخصية من قبل الشركة إلى أطراف ثالثة موجودة في المنطقة الاقتصادية الأوروبية وكذلك إلى المنطقة الاقتصادية غير الأوروبية فقط للأغراض الموضحة في هذه السياسة.",
          "Üçüncü taraflar arasında Muhasebeciler, Denetçiler, Hukuk Müşavirleri, Bankalar ve diğer Kredi Kuruluşları, Ödeme Sistemleri ve Ödeme Hizmet Sağlayıcıları, Pazarlama ve Müşteri Destek Kuruluşları, Şirketin sözleşme ilişkisi içinde olduğu Hizmet Sağlayıcılar ve Şirket ile aynı Şirketler grubu içindeki kuruluşlar yer alabilir. Şirket.": "Tقد تشمل الأطراف الخارجية المحاسبين والمراجعين والاستشاريين القانونيين والبنوك ومؤسسات الائتمان الأخرى وأنظمة الدفع ومقدمي خدمات الدفع ووكالات التسويق ودعم العملاء ومقدمي الخدمات الذين تحتفظ الشركة معهم بعلاقة تعاقدية وكيانات داخل نفس مجموعة الشركات مثل شركة.",
          "Müşterinin kimliğinin belirlenmesi ve doğrulanması amacıyla Şirket, Müşterinin verilerini, ilgili yasal gerekliliklere uygun olarak, bu tür bir tanımlamayı gerçekleştirmeye yetkili bir üçüncü taraf hizmet sağlayıcısına iletecektir. Şirket, iletilen verilerin mahremiyetini ve gizliliğini sağlayacaktır.": "لأغراض تحديد هوية العميل والتحقق منها ، يتعين على الشركة نقل بيانات العميل إلى مزود خدمة تابع لجهة خارجية مرخص له بإجراء مثل هذا التعريف ، بما يتوافق مع المتطلبات التنظيمية ذات الصلة. يجب على الشركة ضمان خصوصية وسرية البيانات المرسلة.",
          "Şirket, herhangi bir düzenleyici kurum, kamu kurumu, mali istihbarat birimi, polis, devlet dairesi, vergi dairesi, kanun uygulayıcı kurumlar tarafından talimat verilmesi halinde veya kanunen gerekli olması halinde veya Şirket'in Müşterinin kişisel verilerini üçüncü şahıslara aktarma hakkını saklı tutar. mal ve/veya kişilere yönelik yakın fiziksel zararı veya zararı önlemek için bilgi paylaşımının gerekli olduğunu düşünmektedir.": "تحتفظ الشركة بالحق في نقل البيانات الشخصية للعميل إلى أطراف ثالثة إذا طلبت من أي سلطة تنظيمية أو وكالة عامة أو وحدة استخبارات مالية أو شرطة أو مكتب حكومي أو سلطة ضريبية أو هيئات إنفاذ القانون ، أو إذا كان ذلك مطلوبًا بموجب القانون ، أو في حالة الشركة تعتبر أن مشاركة المعلومات ضرورية لمنع حدوث ضرر مادي وشيك أو ضرر للممتلكات و / أو الأشخاص.",
          "Verilerin Şirket ile aynı Şirketler grubundaki kuruluşlara aktarımı, yalnızca Müşteriye hizmetlerin sağlanması için sınırlı olacaktır.": "يجب أن يكون نقل البيانات إلى الكيانات داخل نفس مجموعة الشركات مثل الشركة محدودًا وفقط إذا لزم الأمر ، لتوفير الخدمات للعميل.",
          "Müşteri, bir iş ilişkisinin kurulmasına devam ederek, bu tür aktarımlara/aktarmalara açık ve net bir onay verir.": "من خلال الشروع في إقامة علاقة عمل ، يعطي العميل موافقة صريحة لا لبس فيها على هذا التحويل / التحويلات.",
          "Çerezler": "بسكويت",
          "Çerezler, Müşterinin Şirketin web sitesini/sitelerini ve/veya ticaret yazılımını kullanmasının bir sonucu olarak bilgisayarın sabit diskinde depolanan, genellikle benzersiz bir kimlik numarası veya değeri içeren küçük bilgi dosyalarıdır. Şirket, web sitesinin etkin çalışması ve bakımı için çerezleri kullanır.": "ملفات تعريف الارتباط هي ملفات صغيرة من المعلومات ، والتي غالبًا ما تتضمن رقم تعريف فريدًا أو قيمة يتم تخزينها على محرك الأقراص الثابتة بجهاز الكمبيوتر نتيجة لاستخدام العميل لموقع / مواقع الشركة على الويب و / أو برامج التداول. تستخدم الشركة ملفات تعريف الارتباط للتشغيل والصيانة الفعالة لموقعها على الويب.",
          "Şirket, hem oturum kimliği tanımlama bilgilerini hem de kalıcı tanımlama bilgilerini kullanabilir. Oturum kimliği tanımlama bilgisi, İstemci tarayıcıyı kapattığında sona erer. Kalıcı bir çerez, Müşterinin sabit diskinde uzun bir süre kalır. Kalıcı çerezler, Şirketin web sitesi ziyaretçilerinin konumunu ve ilgi alanlarını izlemesini ve hedeflemesini ve Şirketin web sitesini/sitelerini ziyaret ederek deneyimlerini geliştirmesini sağlar. Teknolojik destek için Şirketin web sitesine/sitelerine erişimi olabilecek üçüncü şahıslar kendi çerezlerini kullanabilirler.": "قد تستخدم الشركة كلاً من ملفات تعريف الارتباط الخاصة بمعرف الجلسة وملفات تعريف الارتباط الدائمة. تنتهي صلاحية ملف تعريف ارتباط معرف الجلسة عندما يغلق العميل المتصفح. يظل ملف تعريف الارتباط الدائم على محرك الأقراص الثابتة للعميل لفترة طويلة من الزمن. تمكّن ملفات تعريف الارتباط الدائمة الشركة من تتبع واستهداف موقع واهتمامات زوار موقع الويب وتعزيز تجربتهم من زيارة موقع / مواقع الشركة. قد تستخدم الجهات الخارجية التي قد يكون لها حق الوصول إلى موقع / مواقع الشركة للحصول على الدعم التكنولوجي ملفات تعريف الارتباط الخاصة بها.",
          "Güvenlik": "حماية",
          "Şirket, Müşterinin sağladığı verilerin ve kişisel bilgilerin güvenliğinin, bütünlüğünün ve gizliliğinin kötüye kullanılmamasını sağlamak için uygun önlemleri sürdürmek için çaba göstermektedir. Bu tür sistemler ve prosedürler güvenlik ihlali riskini azaltırken, mutlak güvenlik sağlamazlar. Bu nedenle Şirket, hizmetlerin herhangi bir suiistimalden, arızadan, yasa dışı müdahalelerden veya burada saklanan bilgilere yetkisiz erişimden ve diğer bilgi güvenliği risklerinden veya Müşterinin Şirketin web sitesi/siteleri (dahil olmak üzere) üzerindeki veya bunlar aracılığıyla yaptığı özel iletişimlerden muaf olacağını garanti edemez. iletişim arayüzleri) özel kalacaktır.": "تبذل الشركة جهودًا للحفاظ على الضمانات المناسبة من أجل ضمان عدم إساءة استخدام أمان وسلامة وخصوصية البيانات والمعلومات الشخصية التي قدمها العميل. في حين أن هذه الأنظمة والإجراءات تقلل من مخاطر الخروقات الأمنية ، إلا أنها لا توفر أمانًا مطلقًا. لذلك ، لا يمكن للشركة أن تضمن أن الخدمات ستكون في مأمن من أي مخالفات أو أعطال أو اعتراضات غير قانونية أو وصول غير مصرح به إلى المعلومات المخزنة فيها وإلى مخاطر أمن المعلومات الأخرى ، أو اتصالات العميل الخاصة على أو من خلال موقع / مواقع الشركة (بما في ذلك) واجهات الاتصال) ستبقى خاصة.",
          "Linkler": "الروابط",
          "Müşteri, Şirketin web sitesini/sitelerini kullandığında, başka web sitelerine bağlantı verebilir. Bu Gizlilik ve Güvenlik Politikası, bu diğer siteler için geçerli değildir. Şirket, bu diğer siteler aracılığıyla üçüncü şahıslar tarafından toplanan hiçbir kişisel bilgiden sorumlu değildir.": "عندما يستخدم العميل موقع / مواقع الشركة ، قد يكون قادرًا على الارتباط بمواقع ويب أخرى. لا تنطبق سياسة الخصوصية والأمان هذه على تلك المواقع الأخرى. الشركة ليست مسؤولة عن أي معلومات شخصية يتم جمعها من قبل أطراف ثالثة عبر تلك المواقع الأخرى.",
          "Yürütme ve Değişiklikler": "الإنفاذ والتعديلات",
          "Müşteri, kayıt işlemine devam ederek, kişisel verilerin toplanması, saklanması ve kullanılması ve üçüncü taraflara iletilmesi dahil olmak üzere bu Politika'da belirtilen tüm hükümleri kabul ve muvafakat eder.": "يوافق العميل من خلال متابعة عملية التسجيل ويوافق على جميع الأحكام المنصوص عليها في هذه السياسة بما في ذلك جمع البيانات الشخصية والاحتفاظ بها واستخدامها ونقلها إلى أطراف ثالثة.",
          "Şirket, bu Politikanın şartlarını zaman zaman değiştirebilir ve güncelleyebilir. En güncel versiyon Şirketin internet sitesinde yer alacaktır. Müşterinin, kişisel verilerle ilgili güncel bilgilerden haberdar olması için web sitesini sık sık ziyaret etmesi tavsiye edilir.": "يجوز للشركة تغيير وتحديث شروط هذه السياسة من وقت لآخر. يجب أن يكون أحدث إصدار متاحًا على موقع الشركة على الويب. يُنصح العميل بزيارة الموقع بشكل متكرر من أجل التعرف على المعلومات المحدثة فيما يتعلق بالبيانات الشخصية.",
          "Müşterinin bu Gizlilik ve Güvenlik Politikası ve bunların uygulanmasıyla ilgili herhangi bir sorusu veya endişesi varsa, bunları şu adrese yönlendirebilir:support@btcruka.com": "إذا كان لدى العميل أي أسئلة أو مخاوف بشأن سياسة الخصوصية والأمان هذه وإنفاذها ، فيمكنه إحالتها إلى: support@btcruka.com",

          "İşlem Platformları": "منصات التداول",
          "İşlevsellik, gelişmiş grafikler, hız ve performans RukaFx işlem platformlarında, forex piyasasının bütün avantajlarını yatırımcılarına 7 gün 24 saat hızlı platformu ile sunmaktadır.": "Functionality, advanced graphics, speed and performance RukaFx offers all the advantages of the forex market to its investors on its trading platforms, with its fast platform 24 hours a day, 7 days a week.",
          "Mobil Uygulamalarımızla Dilediğiniz Zaman İşlem Yapabilirsiniz!": "يمكنك التداول وقتما تشاء مع تطبيقات الهاتف المحمول الخاصة بنا!",
          "Kazançlı Yatırımlara Hazır Olun": "استعد للاستثمارات المربحة",
          "Profesyonel yatırımcıların tercihi Sirix'i Zenginleşmiş işlem fonksiyonlarının yanı sıra geliştirilmiş teknik ve temel analiz özellikleri ile Sirix, farklı piyasaları tek platformda sunma becerisyle önplana çıkıyor. Finansal piyaslardaki başarılı işlemler, üstün donanımlı ve çok fonksiyonlu işlem platformu ile mümkündür.": "Sirix ، المفضل لدى المستثمرين المحترفين. بفضل ميزات التحليل الفني والأساسي المحسّنة بالإضافة إلى وظائف التداول الغنية ، تتميز Sirix بقدرتها على تقديم أسواق مختلفة على منصة واحدة. المعاملات الناجحة في الأسواق المالية ممكنة من خلال منصة تداول متعددة الوظائف ومجهزة تجهيزًا عاليًا.",
        }
      }
    }
  });

export default i18n;