const promotionsModel = [
    {
        title: "%20 Hoşgeldin Bonusu",
        link: "20-welcome-bonus",
        src: "temp/hosgeldin-bonusu-1.png",
        srcSet: "temp/hosgeldin-bonusu-1.png",
        description: [
            "RUKAFX’te gerçek hesap açtıktan sonra ilk yatırımınızda geçerlidir.",
            "Kazanılan bonus miktarı yapılacak forex işlemlerinde marjin seviyesini yükseltmek için takviye görevi görür, çekilemez. Fakat bonus kullanılarak elde edilen kâr çekilebilir. Bonus alındıktan sonra stop out olunması durumunda, yatırımcının stop out olduktan sonra hesabında kalan meblağ almış olduğu bonus miktarı veya daha azı kadar ise, hesabında kalan meblağ almış olduğu bonusa eşdeğer sayılacağı için bu miktar için çekim talebinde bulunulamaz. Örneğin; 1.000 $ yatırıp %30 Yatırım Bonusu ile 300$ hoşgeldin bonusu almış olan bir yatırımcı stop out olması durumunda, hesabında 300 $ veya daha düşük bir miktar kaldığında, bu meblağ yatırımcının hesabından düşülür.",
            "Promosyona katılım için web sitemizden RUKAFX Canlı Destek Hattı veya Whatsapp üzerinden müşteri temsilcileri ile iletişime geçilmesi yeterlidir.",
            "RUKAFX, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.",
            "Maksimum alınabilir bonus miktarı 5000$’dır.",
            "Bu kampanya, başka kampanya ile birleştirilemez. RUKAFX kampanya koşullarında değşiklik yapma hakkını saklı tutar."
        ]
    },
    {
        title: "%10 Referans Bonusu",
        link: "10-reference-bonus",
        src: "temp/referans-promosyon.png",
        srcSet: "temp/referans-promosyon.png",
        description: [
            "Gerçek hesap sahibi olan yatırımcılarımız şirketimize yeni yatırımcılar için referans olması durumunda ilk yatırımları üzerinden %10 bonus kampanyasına hak kazanabilir.",
            "Tüm yatırımcılarımızın yeni referanslarının sadece ilk yatırımlarında geçerli olan bonus kampanyasıdır.",
            "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.",
            "Referans bonusu, başka herhangi bir kampanya ile birleştirilemez.",
            "Yatırımcılarımız elde ettikleri karlarını çekerken bonus tutarları silinmez.",
            "Bonus hak edişi elde edilirken yatırılmış olan fon miktarı çekilmek istenirse öncesinde bonus tutarının silinmesi gerekmektedir.",
            "RUKAFX bu kampanyayı değiştirme veya tamamen iptal etme hakkına sahiptir. Kampanyada yapılacak herhangi bir değişikliği takip etme sorumluluğu yatırımcılarımızın kendilerine aittir.",
            "Bu bonustan hak edilebilecek max. bonus tutarı 1000$’dır.",
        ]
    },
    {
        title: "Kayıp Sigorta Kampanyası",
        link: "loss-insurance-campaign",
        src: "temp/sigorta.png",
        srcSet: "temp/sigorta.png",
        description: [
            "Kampanyadan faydalanmak için alt deposit tutarı min. 300$’dır.",
            "Bu kampanya sadece yeni müşteriler ile hesabı stop out olan yatırımcılar için geçerlidir.",
            "Yatırımcının deposit sonrası işleme başladığı gün itibari ile 3. Günün sonunda kaybı hesaplanır, bir sonraki gün kaybının yarısı iade edilir.",
            "Yatırımcı bu kampanyadan yararlanabilmek için major paritelerde işlem yapmalıdır.",
            "Bu kampanyadan kazanılabilecek maksimum bonus tutarı 1000$'dır.",
            "Bu kampanya, başka kampanya ile birleştirilemez. RUKAFX kampanya koşullarında değşiklik yapma hakkını saklı tutar.",
        ]
    },
    {
        title: "Hesabını RUKAFX’e Taşıyan Herkese %30'a Kadar Bonus Fırsatı",
        link: "account-transfer-bonus",
        src: "temp/hesap-tasiyan-herkes.png",
        srcSet: "temp/hesap-tasima.png",
        description: [
            "Hesap Taşıma Bonusu, farklı bir aracı kurumdan RUKAFX’e taşınacak olan aktif forex hesabındaki varlık (equity) miktarının %30’i kadar olup, her yatırımcı bu promosyondan yalnızca 1 kez faydalanabilir.",
            "%30 Hesap Taşıma Bonusu’ndan kazanılabilecek maksimum bonus miktarı 3000$’dır.",
            "Promosyona katılım için web sitemizden RUKAFX Canlı Destek Hattı veya Whatsapp üzerinden müşteri temsilcileri ile iletişime geçilmesi yeterlidir.",
            "RUKAFX, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.",
            "Ana paranızın %80 'ini çektiğiniz taktirde ana paranızla almış olduğunuz bonus hesabınızdan düşülücektir.",
            "Kullanıcının kural dışı faaliyetlerde bulunduğu ya da hileye başvurduğu tespit edilir ise hiçbir mazeret belirtilmeden RUKAFX hesabı kapatılacaktır.",
        ]
    },
    {
        title: "%30 Kripto Para Yatırımına Özel Hoşgeldin Bonusu",
        link: "cryptocurrency-deposit-bonus",
        src: "temp/kripto-hosgeldin.png",
        srcSet: "temp/kripto-hosgeldin.png",
        description: [
            "Bu promosyondan ilk kez yatırımda bulunacak olan üyeler faydalanabilir.",
            "Bu promosyondan faydalanabilmesi için yatırımcının hesabına fonlamayı kabul edilen kripto paralar ile gerçekleştirmesi gerekmektedir.",
            "Kazanılan bonus miktarı marjin seviyenizi yükseltmek için destek niteliği taşır, çekilemez. Fakat bonus kullanılarak sağlanan kazanç çekilebilmektedir.",
            "Promosyona katılmak için canlı destek hattı veya tarafınıza özel atanan müşteri temsilciniz ile iletişime geçmeniz yeterlidir.",
            "Yatırımcı çekim talebi verdiğinde bonus hesabından silinmektedir.",
            "RUKAFX bu promosyonu değiştirme veya iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir."
        ]
    },
    {
        title: "%20 Stop Out Bonusu",
        link: "20-stopout-bonus",
        src: "temp/stop-out.png",
        srcSet: "temp/stop-out.png",
        description: [
            // "Stop out olduktan sonra yatırımcılarımızın yapacakları yeni yatırımlarda kullanabilecekleri kampanyamızdır.",
            "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.",
            "Bonus kampanyasından yararlanan yatırımcılarımız başka herhangi bir kampanyadan yararlanamazlar.",
            "Yatırımcılarımızın yapmış oldukları işlemlerde hesabın güçlendirilmesine ve daha yüksek oranlar ile yatırım yapmasına imkan sağlar.",
            "RUKAFX bu kampanyayı değiştirme veya tamamen iptal etme hakkına sahiptir. Kampanyada yapılacak herhangi bir değişikliği takip etme sorumluluğu yatırımcılarımızın kendilerine aittir.",
        ]
    },
    {
        title: "%10 Yatırım Bonusu",
        link: "10-deposit-bonus",
        src: "temp/yatirim-bonusu.png",
        srcSet: "temp/yatirim-bonusu.png",
        description: [
            "RUKAFX’te yapacağınız her yatırıma %10 bonus kazanın!",
            "Her yatırımcı her para yatırma işlemi için bu kampanyadan yararlanabilir.",
            "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir.",
            "Bonus kampanyasından yararlanan yatırımcılarımız başka herhangi bir kampanyadan yararlanamazlar.",
            "Yatırımcılarımızın yapmış oldukları işlemlerde hesabın güçlendirilmesine ve daha yüksek oranlar ile yatırım yapmasına imkan sağlar.",
            "Yatırımcılarımızın yapmış oldukları işlemlerde hesabın güçlendirilmesine ve daha yüksek oranlar ile yatırım yapmasına imkan sağlar.",
            "Yatırımcılarımızın yapmış oldukları işlemlerde hesabın güçlendirilmesine ve daha yüksek oranlar ile yatırım yapmasına imkan sağlar.",
            "RUKAFX bu kampanyayı değiştirme veya tamamen iptal etme hakkına sahiptir. Kampanyada yapılacak herhangi bir değişikliği takip etme sorumluluğu yatırımcılarımızın kendilerine aittir.",
            "Yatırımcılarımız en fazla 15.000$ alabilmektedir."
        ]
    }
]
export default promotionsModel