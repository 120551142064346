import React from 'react'
import { useTranslation } from 'react-i18next'
import CreateAccountPopup from '../../components/CreateAccount/CreateAccountPopup'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import StartToday from '../../components/StartToday/StartToday'
import AppGlobalEasy from '../../app-static/AppGlobalEasy_1.386_Apkpure.apk'

function TradingPlatforms(props) {
    const { t } = useTranslation()
    var webtrader = 'https://wt.' + (window.location.hostname.replace('www.', '')).replace('#', '');
    return (
        <div>
            <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
            <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
            <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
            <meta name="msapplication-TileColor" content="#666D9B" />
            <meta name="theme-color" content="#666D9B" />
            <div id="viewport">
                <Header />
                <div className="placard" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
                    <div className="img">
                        <img src="temp/placard-komisyonlar.jpg" alt="İşlem Platformları" />
                    </div>
                    <div className="text-container">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <h2 className="page-title">{t("İşlem Platformları")}</h2>
                                    <p>{t("İşlevsellik, gelişmiş grafikler, hız ve performans RukaFx işlem platformlarında, forex piyasasının bütün avantajlarını yatırımcılarına 7 gün 24 saat hızlı platformu ile sunmaktadır.")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-main trader-main" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
                    <div className="container" style={props.language === "fa" ? { direction: "rtl", textAlign: "right" } : {}}>
                        <div className="page-content">
                            <h2 className="page-title" style={props.language === "fa" ? { direction: "rtl", textAlign: "right" } : {}}>{t("Kazançlı Yatırımlara Hazır Olun")}</h2>
                            <p className="mt-5">{t("Profesyonel yatırımcıların tercihi Sirix'i Zenginleşmiş işlem fonksiyonlarının yanı sıra geliştirilmiş teknik ve temel analiz özellikleri ile Sirix, farklı piyasaları tek platformda sunma becerisyle önplana çıkıyor. Finansal piyaslardaki başarılı işlemler, üstün donanımlı ve çok fonksiyonlu işlem platformu ile mümkündür.")}</p>
                            <div className="content-img right stretch">
                            </div>
                            <div className="row">
                                <div className="col-md-7">
                                    <h3 className="page-title text-md">{t("Neden RUKAFX.com?")}</h3>
                                    <img className='tradeImac image-fluid' src="temp/iMac.png" alt="İşlem Platformları" />
                                </div>
                                <div className="col-md-5">
                                    <p>{t("RUKAFX’i tercih etmeniz için onlarca sebepten bazıları;")}</p>
                                    <ul>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Profesyonel Yatırım Hizmetleri")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Rekabetçi Spread/Swap Oranları")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Kriptoda 7/24, FX'de 5/24 Fiyat Akışı")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("5/24 Canlı Destek")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Kredi kartı, Coinler, Havale/Eft ve Daha Bir Çok Yöntem ile Para Yatırma/Çekme İmkanı")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Swaplı,Swapsız Hesap Türleri")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Her Yatırıma Bonus Fırsatı")}</li>
                                        <li style={props.language === "fa" ? { backgroundPosition: "right", paddingRight: "40px" } : {}}>{t("Lot İadesi")}</li>
                                    </ul>
                                    <p>{t("Ve dahası …")}</p>

                                </div>
                            </div>
                            <div className="row" />
                            <div class="trader-link mt-3">
                                <h3 className="page-title text-md text-center mr-4">{t("Mobil Uygulamalarımızla Dilediğiniz Zaman İşlem Yapabilirsiniz!")}</h3>
                                <div className='wtButton row'>
                                    {/* <a className='storeButton mr-4' target="_blank" href="https://apps.apple.com/cn/app/lideno/id6455370237"><img className='storeImage' src='temp/app-store.jpg' /></a> */}
                                    <a className='storeButton mr-4' href={AppGlobalEasy} download="AppGlobalEasy" ><img className='storeImage' src='temp/google-play.jpg' /></a>
                                    <button href={webtrader} style={{ width: '160px' }} className="btn btn-primary mr-4">{t("WebTrader")}</button>
                                </div>
                            </div>
                            {/* <div className="row">
                  <div className="col-12 col-md-4">
                    <h3 className="page-title text-md">Misyon</h3>
                  </div>
                  <div className="col-12 col-md-8">
                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:</p>
                  </div>
                </div> */}
                            <div className="mb-5" />
                            {/* <div className="row">
                  <div className="col-12 col-md-4">
                    <h3 className="page-title text-md">Vizyon</h3>
                  </div>
                  <div className="col-12 col-md-8">
                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:</p>
                  </div>
                </div> */}
                        </div>
                    </div>
                </section>
                <StartToday />
                <Footer />
                <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
                    <CreateAccountPopup />
                </div>
            </div>
        </div>

    )
}

export default TradingPlatforms