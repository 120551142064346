import {connect} from "react-redux";
import {setApp} from "../../store/app";
import {setForm} from "../../store/form";
import { useTranslation } from 'react-i18next';

function StartToday(props){
  const { t } = useTranslation();
    return(
     <div>
          <section className="section-main" style={props.app?{filter: "blur(2px) brightness(0.2)", backgroundColor:"#eee"}:{}}>
            <div className="container" style={ props.language === "fa" ? { direction : "rtl", textAlign: "right"} : {}}>
              <div className="jumbotron foot">
                <div className="d-flex-float-center">
                  <div className="text">
                    <h2 className="title">{t("RUKAFX ile bugün Başlayın!")}</h2>
                    <p className="mb-0">
                    {t("RUKAFX, müşterilerine güvenilir ve șeffaf hizmet anlayışının yanında düșük komisyon prensipleri de sunar. RUKAFX ile yatırım piyasalarındaki alım satım deneyiminizi en üst noktaya çıkarabilirsiniz.")}
                    </p>
                  </div>
                  <div className="right">
                    <a onClick={()=>{props.setApp(true); props.setForm("gercek")}} style={{color:"#F27031"}} className="btn btn-white btn-lg d-block">{t("HESAP OLUŞTUR")}</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
     </div>
    );
}
const mapStateToProps = state => {
  return {
      app: state.app.app,
      form: state.form.form,
      language: state.language.language
  }
}
const mapDispatchToProps = dispatch => {
  return {
      setApp: (app) => dispatch(setApp(app)),
      setForm: (form) => dispatch(setForm(form)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StartToday);