import React from 'react'
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';


function PrivacyAndSecurity() {
  const { t } = useTranslation();
  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard">
          <div className="img">
            <img src="temp/placard-iletisim.jpg" alt="Gizlilik ve Güvenlik Politikası" />
          </div>
          <div className="text-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <h2 className="page-title">{t("Gizlilik ve Güvenlik Politikası")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-main">
          <div className="container">
            <div className="page-content">
              <div style={{ marginTop: "10px" }}>
                <p>{t("BTCRUKA ('Şirket'), geçerli yasal gerekliliklere uygun olarak Müşteriden elde edilen kişisel verilerin mahremiyetini ve gizliliğini korumayı taahhüt eder. Şirket, yalnızca Müşteri ile iş ilişkisi için gerekli olduğu ölçüde veri toplayacaktır.")}</p>
                <p>{t("Bu Gizlilik ve Güvenlik Politikası, Müşterinin web sitelerimizi ziyaretleri sırasında toplanan veriler de dahil olmak üzere, Şirketin Müşteri hakkında kişisel verileri nasıl topladığını, muhafaza ettiğini, kullandığını ve ilettiğini açıklar.")}</p>
                <h3 style={{ margin: "20px" }}>{t("Müşterinin Kişisel Verilerinin Toplanması ve Kullanımı")}</h3>
                <p>{t("Müşterinin, iş ilişkisinin kurulması, hizmetlerin sağlanması ve alım satım hesaplarına ve faaliyetlerine erişim için gerekli kişisel verileri Şirkete sağlaması gerekmektedir. Kayıt işlemi sırasında sağlanan veriler, diğerlerinin yanı sıra; Müşterinin doğum tarihi, tam adı ve soyadı, tam ikamet ve/veya iş adresi, müşterinin kimlik belgelerinde mevcut bilgiler (yani Pasaport / Kimlik Kartı), iletişim bilgileri (yani cep telefonu numarası, e-posta adresi). Bu bilgiler, Şirketin Müşteriyi tanımlamasına ve doğrulamasına, Müşteriye bir ticaret hesabı ve erişim kodu/şifresi etkinleştirmesine ve sağlamasına ve Müşteri ile iletişime geçmesine ve mevcut hizmetler, ürünler ve promosyonlar hakkında bilgi sağlamasına olanak tanır.")}</p>
                <p>{t("Şirket ayrıca, Müşterinin web sitelerini, özel arayüzleri veya iletişim kanallarını, ticaret yazılımlarını veya sağlanan platformları kullanımından da bilgi alır. Bu bilgiler, ziyaret edilen site alanları, görüntülenen sayfalar, ziyaretlerin sıklığı ve süresi, İnternet Protokolü (IP) adresi, bilgisayar tanımlama parametreleri, yürütülen işlem türleri, indirilen belgeler, fare tıklamaları, fare hareketleri ve kaydırma etkinliğini içerebilir.")}</p>
                <p>{t("Şirket, müşterilerinin ihtiyaçlarını karşıladığından emin olmak için anonim istatistiksel bilgiler toplar. Şirket, bu tür verileri toplayarak mevcut ve potansiyel müşterilere sunulan hizmetleri izleyebilir ve iyileştirebilir. Şirket, bu tür bilgileri üçüncü kişilerle paylaşabilir.")}</p>
                <h3 style={{ margin: "20px" }}>{t("Müşteri Bilgilerini Güncelleme")}</h3>
                <p>{t("Müşterinin Şirket ile olan iş ilişkisi sırasında, Müşteriden, yenilenen kimlik belgeleri, güncellenmiş iletişim bilgileri dahil olmak üzere, bu ilişkinin sürdürülmesi için gerekli olan kişisel verileri sağlaması istenebilir.")}</p>
                <p>{t("Ayrıca Müşteri, kişisel bilgilerdeki herhangi bir değişikliği Şirkete bildirebilir. Şirket, Müşterinin kişisel bilgilerini Müşterinin talimatlarına uygun olarak değiştirecek ve Şirketin ilgili kişisel verileri yasal veya yasal amaçlarla tutması ve/veya Müşteriye talep edilen hizmetleri sunması gerektiği durumlar dışında, önceden elde edilen bilgileri silecektir. veya iş kayıtlarını tutmak için.")}</p>
                <h3 style={{ margin: "20px" }}>{t("Verilerin Üçüncü Kişilere Aktarılması")}</h3>
                <p>{t("Kişisel veriler, Şirket tarafından Avrupa Ekonomik Alanında ve Avrupa Ekonomik Alanı dışında bulunan üçüncü şahıslara yalnızca bu Politikada açıklanan amaçlar doğrultusunda aktarılabilir.")}</p>
                <p>{t("Üçüncü taraflar arasında Muhasebeciler, Denetçiler, Hukuk Müşavirleri, Bankalar ve diğer Kredi Kuruluşları, Ödeme Sistemleri ve Ödeme Hizmet Sağlayıcıları, Pazarlama ve Müşteri Destek Kuruluşları, Şirketin sözleşme ilişkisi içinde olduğu Hizmet Sağlayıcılar ve Şirket ile aynı Şirketler grubu içindeki kuruluşlar yer alabilir. Şirket.")}</p>
                <p>{t("Müşterinin kimliğinin belirlenmesi ve doğrulanması amacıyla Şirket, Müşterinin verilerini, ilgili yasal gerekliliklere uygun olarak, bu tür bir tanımlamayı gerçekleştirmeye yetkili bir üçüncü taraf hizmet sağlayıcısına iletecektir. Şirket, iletilen verilerin mahremiyetini ve gizliliğini sağlayacaktır.")}</p>
                <p>{t("Şirket, herhangi bir düzenleyici kurum, kamu kurumu, mali istihbarat birimi, polis, devlet dairesi, vergi dairesi, kanun uygulayıcı kurumlar tarafından talimat verilmesi halinde veya kanunen gerekli olması halinde veya Şirket'in Müşterinin kişisel verilerini üçüncü şahıslara aktarma hakkını saklı tutar. mal ve/veya kişilere yönelik yakın fiziksel zararı veya zararı önlemek için bilgi paylaşımının gerekli olduğunu düşünmektedir.")}</p>
                <p>{t("Verilerin Şirket ile aynı Şirketler grubundaki kuruluşlara aktarımı, yalnızca Müşteriye hizmetlerin sağlanması için sınırlı olacaktır.")}</p>
                <div>{t("Müşteri, bir iş ilişkisinin kurulmasına devam ederek, bu tür aktarımlara/aktarmalara açık ve net bir onay verir.")}</div>
                <h3 style={{ margin: "20px" }}>{t("Çerezler")}</h3>
                <p>{t("Çerezler, Müşterinin Şirketin web sitesini/sitelerini ve/veya ticaret yazılımını kullanmasının bir sonucu olarak bilgisayarın sabit diskinde depolanan, genellikle benzersiz bir kimlik numarası veya değeri içeren küçük bilgi dosyalarıdır. Şirket, web sitesinin etkin çalışması ve bakımı için çerezleri kullanır.")}</p>
                <p>{t("Şirket, hem oturum kimliği tanımlama bilgilerini hem de kalıcı tanımlama bilgilerini kullanabilir. Oturum kimliği tanımlama bilgisi, İstemci tarayıcıyı kapattığında sona erer. Kalıcı bir çerez, Müşterinin sabit diskinde uzun bir süre kalır. Kalıcı çerezler, Şirketin web sitesi ziyaretçilerinin konumunu ve ilgi alanlarını izlemesini ve hedeflemesini ve Şirketin web sitesini/sitelerini ziyaret ederek deneyimlerini geliştirmesini sağlar. Teknolojik destek için Şirketin web sitesine/sitelerine erişimi olabilecek üçüncü şahıslar kendi çerezlerini kullanabilirler.")}</p>
                <h3 style={{ margin: "20px" }}>{t("Güvenlik")}</h3>
                <p>{t("Şirket, Müşterinin sağladığı verilerin ve kişisel bilgilerin güvenliğinin, bütünlüğünün ve gizliliğinin kötüye kullanılmamasını sağlamak için uygun önlemleri sürdürmek için çaba göstermektedir. Bu tür sistemler ve prosedürler güvenlik ihlali riskini azaltırken, mutlak güvenlik sağlamazlar. Bu nedenle Şirket, hizmetlerin herhangi bir suiistimalden, arızadan, yasa dışı müdahalelerden veya burada saklanan bilgilere yetkisiz erişimden ve diğer bilgi güvenliği risklerinden veya Müşterinin Şirketin web sitesi/siteleri (dahil olmak üzere) üzerindeki veya bunlar aracılığıyla yaptığı özel iletişimlerden muaf olacağını garanti edemez. iletişim arayüzleri) özel kalacaktır.")}</p>
                <h3 style={{ margin: "20px" }}>{t("Linkler")}</h3>
                <p>{t("Müşteri, Şirketin web sitesini/sitelerini kullandığında, başka web sitelerine bağlantı verebilir. Bu Gizlilik ve Güvenlik Politikası, bu diğer siteler için geçerli değildir. Şirket, bu diğer siteler aracılığıyla üçüncü şahıslar tarafından toplanan hiçbir kişisel bilgiden sorumlu değildir.")}</p>
                <h3 style={{ margin: "20px" }}>{t("Yürütme ve Değişiklikler")}</h3>
                <p>{t("Müşteri, kayıt işlemine devam ederek, kişisel verilerin toplanması, saklanması ve kullanılması ve üçüncü taraflara iletilmesi dahil olmak üzere bu Politika'da belirtilen tüm hükümleri kabul ve muvafakat eder.")}</p>
                <p>{t("Şirket, bu Politikanın şartlarını zaman zaman değiştirebilir ve güncelleyebilir. En güncel versiyon Şirketin internet sitesinde yer alacaktır. Müşterinin, kişisel verilerle ilgili güncel bilgilerden haberdar olması için web sitesini sık sık ziyaret etmesi tavsiye edilir.")}</p>
                <p>{t("Müşterinin bu Gizlilik ve Güvenlik Politikası ve bunların uygulanmasıyla ilgili herhangi bir sorusu veya endişesi varsa, bunları şu adrese yönlendirebilir:support@btcruka.com")}</p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default PrivacyAndSecurity