const WhyRUKAFX = [
    {
        title:"Ücretsiz Eğitim",
        icon: "ui-icon-segwit",
        description:"Profesyonel eğitmenlerimizden telefonla ve ücretsiz olarak bire bir eğitim alarak yatırım hayatınıza bugün başlayabilirsiniz."
    },
    {
        title:"Hızlı Platform",
        icon: "ui-icon-hizli-platform",
        description:"Modern ve teknolojik altyapımız ve sunucu desteğimiz ile hızlı ve güvenli işlem amaçlıyoruz."
    },
    {
        title:"Lisanslı İşlem Platformu",
        icon: "ui-icon-onay-islemi",
        description:"5/24 dünya ekonomisine yön veren para piyasalarında yatırım yapabilmeniz için lisanslı işlem platformumuz her an elinizin altında."
    },
    {
        title:"Sıfır Risk ile Yatırımı Öğrenin",
        icon: "ui-icon-api-destegi",
        description:"Yatırım işlemlerinize başlamadan önce ücretsiz olarak açacağınız Demo Hesap’ta 100.000 USD deneme bakiyesi ile işlem yapmayı risk almadan öğreneceksiniz."
    },
    {
        title:"Kaldıraçlı İşlem",
        icon: "ui-icon-soguk-depolama",
        description:"Depozit olarak düşük miktarlarla yüksek pozisyonlar alınmasını sağlayarak yatırımdan en yüksek kârın elde edilmesini kolaylaştıran bir araçtır."
    },
    {
        title:"Çift Yönlü İşlem",
        icon: "ui-icon-yuksek-derinlik",
        description:"Yatırım araçlarının fiyatlarından hem düşerken hem de yükselirken para kazanabilirsiniz."
    },
]
export default WhyRUKAFX