const LiveTitle = [
    {
        title: "5/24 Canlı Destek ve Küresel Piyasalarda Yatırım Avantajı",
    },
    {
        title: "En Hızlı Para Yatırma ve Çekme İşlemleri",
    },
    {
        title: "Güvenli ve Avantajlı Kripto Para Yatırımı",
    },
    {
        title: "RukaFX ile Altın, Döviz, ve Hisse Senetleri Alın-Satın",
    },

]
export default LiveTitle