import React, { useState } from "react"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import AnnouncementModel from "../../model/AnnouncementModel"
import AnnouncementPopup from "../../components/AnnouncementPopup/AnnouncementPopup"
import { useTranslation } from 'react-i18next';

function Announcement(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="img">
            <img src="temp/placard-duyurular.jpg" alt="Duyurular" />
          </div>
          <div className="text-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <h2 className="page-title">{t("Duyurular")}</h2>
                  <p>{t("RUKAFX ile ilgili tüm güncel duyuruları bu kısımdan takip edebilirsiniz.")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-main" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container" style={props.language === "fa" ? { direction: "rtl", textAlign: "right" } : {}}>
            <div className="page-content">
              <div className="row">
                {
                  AnnouncementModel.map((item, index) => {
                    return (
                      <div key={"Announcement-" + index} className="col-12 col-sm-4 content">
                        <a href="#announcement-box" onClick={() => { setDescription(t(item.description)); setDate(item.date); setTitle(t(item.title)) }} className="fancy-box">
                          <div className="card inner">
                            <div className="card-body">
                              <i className="ui-icon ui-icon-anouncement" />
                              <h3 className="card-title mb-3">{t(item.title)}</h3>
                              <time>{item.date}</time>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })
                }
              </div>
              <div className="text-center">
                {/* <button className="btn show-more" data-target="anouncements">
                    <i className="ui-icon ui-icon-circle-plus" />
                    Daha fazla göster    
                    <span className="spinner-border spinner-border-sm ml-2 align-middle d-none" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </button> */}
              </div>
              <div className="d-none">
                <div id="anouncement-detail">
                </div>
              </div>
            </div>
          </div>
        </section>
        <StartToday />
        <Footer />
        <div className="d-none">
          <AnnouncementPopup title={title} description={description} date={date} />
        </div>
        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
    language: state.language.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Announcement);