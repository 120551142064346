import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import StartToday from "../../components/StartToday/StartToday";
import CreateAccountPopup from "../../components/CreateAccount/CreateAccountPopup"
import { connect } from "react-redux";
import { setApp } from "../../store/app";
import { setForm } from "../../store/form";
import { useTranslation } from 'react-i18next';

function Forex(props) {
  const { t } = useTranslation();
  return (
    <div>
      <title>{t("RukaFX - Güvenilir Global Yatırım Ortağınız")}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1, user-scalable=no" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="stylesheet" href="assets/css/libs.min.css?v=0.0.1" />
      <link rel="stylesheet" href="assets/css/app.min.css?v=0.0.1" />
      <link rel="shortcut icon" href="./assets/img/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#666D9B" />
      <meta name="theme-color" content="#666D9B" />
      <div id="viewport">
        <Header />
        <div className="placard" style={props.app ? { filter: "blur(2px)" } : {}}>
          <div className="img">
            <img src="temp/placard-komisyonlar.jpg" alt="Komisyonlar" />
          </div>
          <div className="text-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <h2 className="page-title">{t("Forex")}</h2>

                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-main" style={props.app ? { filter: "blur(2px) brightness(0.2)", backgroundColor: "#eee" } : {}}>
          <div className="container" style={props.language === "fa" ? { direction: "rtl", textAlign: "right" } : {}}>
            <div className="page-content">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h2 className="page-title mb-5">{t("Forex Nedir?")}</h2>
                  <p className="pt-5">{t("Forex veya kısa ismiyle FX, ingilizce ‘Foreign Exchange’ sözcüklerinin kısaltmasıdır. Forex piyasası da farklı ülke para birimlerinin, birbirlerine karşı görece değerlerinin, kaldıraçlı olarak alınıp satılmasına olanak sağlayan tezgahüstü bir döviz piyasasıdır.")}</p>
                  <p>{t("Merkez bankalarından, uluslararası finans kuruluşlarına, serbest fonlara ve dünyanın her yerinden bireysel yatırımcılara kadar çok geniş bir yatırımcı kitlesine sahip olan Forex piyasası, bu yönüyle dünyanın en likit ve en hacimli (günlük ortalama 5 trilyon dolar) finansal piyasasıdır. Ayrıca Forex piyasaları 5 gün boyunca 24 saat açık olması nedeniyle de dünyanın farklı noktalarından gelebilecek veri akışına anında tepki verebilmektedir.")}</p>

                  <div className="row" style={{ marginTop: "200px" }}>
                    <div className="col-xs-12 col-sm-6 col-md-6 mb-5 text-center">
                      <a onClick={() => { props.setForm("demo"); props.setApp(true) }} className="btn btn-primary btn-lg d-md-block" style={{ color: "#fff" }}>{t("DEMO HESAP AÇ")}</a>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 mb-5 text-center">
                      <a href="/promotions" className="btn btn-primary btn-lg d-md-block" style={{ color: "#fff" }}>{t("FOREX KAMPANYALARI")}</a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="content-img mt-0 center stretch">
                    <img src="temp/komisyon-1.png" alt="Title" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <StartToday />
        <Footer />
        <div style={props.app ? { position: "fixed", top: 0, zIndex: "9999999999" } : { display: "none" }}>
          <CreateAccountPopup />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    app: state.app.app,
    form: state.form.form,
    language: state.language.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: (app) => dispatch(setApp(app)),
    setForm: (form) => dispatch(setForm(form)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Forex);