import {connect} from "react-redux";

function AnnouncementPopup(props){
    return(
        <div id="announcement-box" style={{width:"50%"}}>
        <div className="page-content mt-0" style={ props.language === "fa" ? { direction : "rtl", textAlign: "right"} : {}}>
            <div className="collapse coin-buy-panel show">
                    <i style={{display:"inline-block",fontSize:"64px",color:"#F27031",marginBottom:"1.3rem"}} className="ui-icon ui-icon-anouncement" />
                <h2 className="page-title text-md mb-3" style={ props.language === "fa" ? { direction : "rtl", textAlign: "right", color: "#F27031"} : {color: "#F27031"}}>{props.title}</h2>
                <p className="mb-5 text-lg">{props.date}</p>
                <p >{props.description}</p>
            </div>
        </div>
        </div>
    );

}
const mapStateToProps = state => {
    return {
        language: state.language.language
    }
  }
  const mapDispatchToProps = dispatch => {
    return { }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementPopup);

